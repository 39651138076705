<template>
    <painel-component :title="'Adicionar'">
        <ErrorComponent :errors="errors" v-if="showError" />

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch"
                        :options="selectActiveInactive" v-model="formDate.status" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>CEP:</label>
                    <input-component v-mask="'#####-###'" :type-input="'text'" v-model="formDate.cep"
                        @change="searchApiCEP" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.city }">
                    <label>Cidade:</label>
                    <input-component :type-input="'text'" v-model="formDate.city" />
                </row-col-component>


                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Bairro:</label>
                    <input-component :type-input="'text'" v-model="formDate.district" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>País:</label>
                    <input-component :type-input="'text'" v-model="formDate.country" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.state }">
                    <label>Estado:</label>
                    <input-component :type-input="'text'" v-model="formDate.state" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.state }">
                    <label>Fusio Horário:</label>
                    <multiselect :searchable="true" :noResultsText="this.$store.state.var.selectTimeZone"
                    :options="selectTimeZone" v-model="formDate.time_zone" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Endereço:</label>
                    <input-component :type-input="'text'" v-model="formDate.address" />
                </row-col-component>

            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Refêrencia:</label>
                    <input-text-area :rows="4" v-model="formDate.complement" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import InputTextArea from '@/components/forms/InputTextArea';
import Multiselect from '@vueform/multiselect';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';

import { mask } from 'vue-the-mask'
import { ACTION_MESSAGES } from '@/configs/messages'
import { ESTADOS } from '@/configs/constants'

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Empresa')
        this.$store.commit('PRELOADER', true)
        this.checkACL()
    },

    name: 'CompanyCreate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        InputTextArea,
        Multiselect,
        ButtonOne,
        ButtonAdd,
    },

    directives: { mask },

    data() {
        return {
            buttonAdd: {
                route: '/company/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                icon: 'plus',
                type: 'button',
                name: 'Adicionar',
                tooltip: 'Adicionar'
            },

            formDate: {
                name: '',
                status: 1,
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
                time_zone: ''
            },

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeCompany', this.formDate)
                    .then((r) => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.postDefault)
                        this.$router.push({ name: 'company-edit', params: { id: r.data } })
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 4)
                .then((r) => {
                    if (r.data.indexOf('company-create') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },

        resetFormAddresse() {
            this.formDate.city = ''
            this.formDate.state = ''
            this.formDate.district = ''
            this.formDate.address = ''
            this.formDate.complement = ''
            this.formDate.country = ''
            this.formDate.time_zone = ''
        },

        searchApiCEP() {
            this.resetFormAddresse()
            let cep = this.formDate.cep.replace(/\D/g, '');
            const dataCEP = { 'cep': cep }
            this.$store.dispatch('searchApiCEP', dataCEP)
                .then((r) => {
                    this.formDate.city = r.data.localidade
                    this.formDate.state = ESTADOS[r.data.uf]
                    this.formDate.district = r.data.bairro
                    this.formDate.address = r.data.logradouro
                    this.formDate.country = 'Brasil'
                    this.formDate.complement = r.data.complemento
                }).catch(() => { })
        },
    },

    computed: {
        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        },

        selectTimeZone(){
            return this.$store.state.var.selectTimeZone
        }
    }

}

</script>