<template>
  <painel-component>
    <div role="button" class="btn-close" @click="this.$emit('close')"></div>

    <error-component :errors="errors" v-if="showError" />
    <!--        -->
    <form>
      <row-component>
        <row-col-component
          class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
          :class="{ formError: errors.spot_id }"
        >
          <label>Local:</label>
          <Multiselect
            label="name"
            :searchable="true"
            :options="selectSpot"
            v-model="formData.spot_id"
            @select="select(formData.spot_id)"
            @clear="clearSelect"
            :noResultsText="noResultsText"
          />
        </row-col-component>

        <row-col-component
          class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
          :class="{ formError: errors.spot_id }"
        >
          <label>Conteúdo:</label>
          <Multiselect
            :searchable="true"
            :options="selectContentSpot"
            v-model="formData.content_folder_id"
            disabled
            :noResultsText="noResultsText"
          />
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component
          class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
          :class="{ formError: errors.imagem }"
        >
          <label>Permitir imagem:</label>
          <Multiselect
            :searchable="true"
            :options="selectNoYes"
            v-model="formData.imagem"
            :noResultsText="noResultsText"
          />
        </row-col-component>

        <row-col-component
          class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
          :class="{ formError: errors.video }"
        >
          <label>Permitir vídeo:</label>
          <Multiselect
            :searchable="true"
            :options="selectNoYes"
            v-model="formData.video"
            :noResultsText="noResultsText"
          />
        </row-col-component>

        <row-col-component
          class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
          :class="{ formError: errors.time_img }"
        >
          <label>Tempo exibição imagem(Segundos):</label>
          <input-component type-input="number" v-model="formData.time_img" />
        </row-col-component>

        <row-col-component
          class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
          :class="{ formError: errors.limit_files }"
        >
          <label>Quantidade de arquivos permitidos:</label>
          <input-component type-input="number" v-model="formData.limit_files" />
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <button-one @submit="submit" :disabled="sendForm" class="float-end" :itens="itemsAdd" />
        </row-col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import Multiselect from '@vueform/multiselect';
import InputComponent from '@/components/forms/InputComponent';
import ButtonOne from '@/components/buttons/ButtonOne';
import { ACTION_MESSAGES } from '@/configs/messages';
import ErrorComponent from '@/components/alerts/ErrorComponent';

export default {
  name: 'CampaignShareUpdate',
  components: {
    ErrorComponent,
    ButtonOne,
    Multiselect,
    InputComponent,
    RowColComponent,
    RowComponent,
    PainelComponent
  },

  props: {
    id: {
      required: true
    },

    id_share: {
      required: true
    }
  },

  created() {
    this.$store.dispatch('loadSelectSpot');
    this.loadItems();
  },

  data() {
    return {
      itemsAdd: {
        tooltip: 'Salvar',
        name: 'Salvar',
        type: 'button'
      },

      formData: {
        campaign_id: this.id,
        content_folder_id: '',
        spot_id: '',
        limit_files: '',
        imagem: '2',
        time_img: '',
        video: '2'
      },

      content: true,

      errors: [],
      sendForm: false,
      showError: false
    };
  },

  methods: {
    loadItems() {
      this.$store
        .dispatch('loadCampaignShare', this.id_share)
        .then(r => {
          this.formData = r;
          this.select(this.formData.spot_id);
        })
        .catch(() => {
          this.$store.commit('addToast', ACTION_MESSAGES.errorDefault);
        });
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('updateCampaignShare', this.formData)
          .then(() => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            this.$store.commit('addToast', ACTION_MESSAGES.updateDefault);
            this.$emit('close', 1);
          })
          .catch(r => {
            this.showError = true;
            this.sendForm = false;
            this.errors = r.response.data.errors;
            this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault);
          });
      }
    },

    select(id) {
      this.$store.dispatch('loadSelectContentSpot', id);
      if (this.formData.spot_id !== '') this.content = false;
    },

    clearSelect() {
      this.formData.spot_id = '';
      this.content = true;
    }
  },

  computed: {
    noResultsText() {
      return this.$store.state.var.selectNotSearch;
    },

    selectNoYes() {
      return this.$store.state.var.selectNoYes;
    },

    selectSpot() {
      return this.$store.state.var.selectSpot;
    },

    selectContentSpot() {
      return this.$store.state.var.selectContentSpot;
    }
  }
};
</script>

<style scoped>
.btn-close {
  top: 15px;
  right: 15px;
  position: absolute;
}
</style>
