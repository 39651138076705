import axios from "axios";
import { URL_BASE } from "@/configs/constants";

export default {
    updateNamePage(context, namePage) {
        context.commit('UPDATE_NAME_PAGE', namePage)
    },

    loadSelectPermissions(context, page) {
        const RESOURCE = page;
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`)
                .then(response => {
                    context.commit('UPDATE_SELECT_PERMISSIONS', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadSelectGroupUser(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}group-list`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_GROUP_USER', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadSelectCompanyRestrict(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}company-list-user`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_COMPANY_RESTRICT', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadSelectCompany(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}company-list`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_COMPANY', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadSelectState(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}city-get-state`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_CITY_STATE', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadSelectCity(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}city-get/${id}`)
                .then(response => {
                    context.commit('UPDATE_SELECT_CITY', response.data)
                    resolve(response.data)
                })
                .catch((e) => reject(e))
        })
    },

    loadSelectResolutionPlayer(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}player-resolution`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_RESOLUTION_PLAYER', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadSelectTypeMediaPlayer(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}player-type-media`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_MEDIA_TYPE_PLAYER', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadSelectSegmentPlayer(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}player-segment`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_SEGMENT_PLAYER', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadSelectSpot(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}spot-list`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_SPOT', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadSelectLayout(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}campaing-list-layout`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_LAYOUT_CAMPAIGN', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadSelectCityClimate(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}app/climate-list-cities`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_CITY_CLIMATE', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadSelectNoticeListUrl(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}app/notice-list-urls`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_NOTICE_LIST_URL', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadFontsMenuTable(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}app/menu-table-fonts`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_MENU_FONTS_TABLE', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

    loadSelectContentFolder(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}content-folder-list-id/${id}`)
                .then(response => {
                    context.commit('UPDATE_SELECT_CONTENT_FOLDER', response.data)
                    resolve(response.data)
                })
                .catch((e) => reject(e))
        })
    },

    loadSelecNoticeApp(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}campaing-list-app-notice`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_NOTICE_APP', response.data)
                    resolve(response.data)
                })
                .catch((e) => reject(e))
        })
    },

    loadSelectVersionApp(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}player-list-version-app`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_VERSION_APP', response.data)
                    resolve(response.data)
                })
                .catch((e) => reject(e))
        })
    },

    loadSelectVersionAndroid(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}player-list-version-android`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_VERSION_ANDROID', response.data)
                    resolve(response.data)
                })
                .catch((e) => reject(e))
        })
    },

    loadSelectObituaryLocalList(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}app/obituary-list-local`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_OBITUARY_LIST', response.data)
                    resolve(response.data)
                })
                .catch((e) => reject(e))
        })
    },

    loadSelectContentSpot(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}content-folder-list-spot/${id}`)
                .then(response => {
                    context.commit('UPDATE_SELECT_CONTENT_SPOT', response.data)
                    resolve(response.data)
                })
                .catch((e) => reject(e))
        })
    },

    loadSelectCompanyLayout(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}campaing-list-layout`, { params })
                .then(response => {
                    context.commit('UPDATE_SELECT_COMPANY_RESTRICT', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
        })
    },

}