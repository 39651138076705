<template>
  <painel-component :title="'Adicionar'">
    <row-col-component
      :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 filter-position'"
      v-if="btnFilter"
    >
      <button-add v-if="buttonCheck.create" class="mx-3" :itens="buttonAdd"></button-add>
      <button class="btn-filter" @click="filterWindow">
        <img src="@/assets/icones/funnel.svg" alt="" />
      </button>
    </row-col-component>

    <form class="mb-4 mt-5">
      <transition name="fade-in-top">
        <row-component v-if="filter">
          <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
            <label>Nome:</label>
            <input-component :type-input="'text'" v-model="formDate.name" />
          </row-col-component>

          <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
            <label>Status:</label>
            <multiselect
              :noResultsText="this.$store.state.var.selectNotSearch"
              :searchable="true"
              :options="selectActiveInactive"
              v-model="formDate.status"
            />
          </row-col-component>

          <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
            <div class="desk-btn"><button-one class="mx-1" :itens="buttonOne" @submit="submit" /></div>
            <div class="mobi-btn"><button-one class="mx-1" :itens="buttonTwo" @submit="submit" /></div>

            <div v-if="!btnFilter" style="display: inline">
              <button-add class="mx-1" :itens="buttonAdd" v-if="buttonCheck.create" />
            </div>
          </row-col-component>
        </row-component>
      </transition>
    </form>

    <div class="__table">
      <table>
        <thead>
          <tr>
            <th scope="col" style="width: 30%">Nome</th>
            <th scope="col" style="width: 30%">Status</th>
            <th scope="col" style="width: 10%" v-if="buttonCheck.show || buttonCheck.edit">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in company.data" :key="index">
            <td data-label="Nome">
              <div class="container-avatar-user-search">
                <img v-if="data.photo" :src="data.photo" />
                <img v-else src="@/assets/img/avatar.png" />
                {{ data.name }}
              </div>
            </td>
            <td data-label="Status">{{ statusString(data.status) }}</td>
            <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.edit">
              <button-edit v-if="buttonCheck.edit" :route="'company-edit'" :id-params="data.id" />
              <button-show v-if="buttonCheck.show" :route="'company-show'" :id-params="data.id" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <button-pagination :pagination="company" :offset="3" @paginate="loadCompanys" />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonShow from '@/components/buttons/ButtonShow';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
  created() {
    this.checkACL();
    this.loadCompanys(1);
    this.$store.commit('PRELOADER', true);
    this.$store.dispatch('updateNamePage', 'Empresa');

    let searchParamsStorage = this.$store.state.company.searchParams;
    if (Object.keys(searchParamsStorage).length > 0) {
      this.formDate = searchParamsStorage;
    }

    if (window.innerWidth <= 991) {
      this.filter = false;
      this.btnFilter = true;
    }
  },

  name: 'CompanySearch',
  components: {
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonAdd,
    ButtonOne,
    ButtonShow,
    ButtonEdit,
    ButtonPagination
  },

  data() {
    return {
      buttonAdd: {
        route: '/company/create',
        icon: 'plus',
        tooltip: 'Adicionar'
      },

      buttonOne: {
        icon: '',
        tooltip: 'Buscar',
        name: 'Buscar',
        type: 'button'
      },

      buttonCheck: {
        create: false,
        edit: false,
        show: false
      },

      buttonTwo: {
        icon: 'search',
        tooltip: '',
        name: '',
        type: 'button'
      },

      formDate: {
        name: '',
        status: ''
      },

      filter: true,
      btnFilter: false,

      searchSubmit: false
    };
  },

  methods: {
    filterWindow() {
      this.filter = !this.filter;
    },

    submit() {
      this.loadCompanys(1);
      this.searchSubmit = true;
    },

    loadCompanys(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadCompanys', { ...this.params, page })
          .then(r => {
            if (this.searchParams == 1) this.$store.commit('UPDATE_SEARCH_COMPANY_APP', this.params);

            this.formDate = this.params;
            this.searchParams = 1;

            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data == '') {
                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert);
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 4)
        .then(r => {
          if (r.data.indexOf('company-search') !== -1) {
            this.$store.commit('PRELOADER', false);

            if (r.data.indexOf('company-search') !== -1) {
              this.buttonCheck.show = true;
            }

            if (r.data.indexOf('company-edit') !== -1) {
              this.buttonCheck.edit = true;
            }

            if (r.data.indexOf('company-create') !== -1) {
              this.buttonCheck.create = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    statusString(value) {
      if (value == 1) return 'Ativo';
      if (value == 2) {
        return 'Inativo';
      }
    }
  },

  computed: {
    company() {
      return this.$store.state.company.items;
    },

    params() {
      let searchParamsStorage = this.$store.state.company.searchParams;
      if (Object.keys(searchParamsStorage).length > 0) return this.$store.state.company.searchParams;

      return {
        page: this.company.current_page,
        name: this.formDate.name,
        status: this.formDate.status
      };
    },

    selectActiveInactive() {
      return this.$store.state.var.selectActiveInactive;
    }
  }
};
</script>
<style src="@/assets/css/filterSearch.css"></style>

<style scoped>
.container-avatar-user-search {
  display: flex;
  align-items: center;
  height: auto;
}

.container-avatar-user-search > img {
  border-radius: 50%;
  margin-right: 15px;
  height: 3rem;
  width: 3rem;
}

.desk-btn {
  display: none;
}

.mobi-btn {
  display: inline;
}

@media (max-width: 991px) {
  .container-avatar-user-search {
    height: 100%;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
  }

  .container-avatar-user-search > img {
    margin-right: 0;
    margin-left: 10px;
    height: 2rem;
    width: 2rem;
  }

  .desk-btn {
    display: inline;
  }

  .mobi-btn {
    display: none;
  }
}
</style>
