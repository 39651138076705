<template>
  <painel-component :title="'Editar'">
    <ErrorComponent :errors="errors" v-if="showError" />
    <button-one v-if="buttonCheck.create" class="add-button" :itens="buttonTwo" @click="routeAdd" />
    <button-one class="location-add-button" :itens="buttonThree" @click="openModalAddSpot(this.id)" />

    <div class="modal-add-spot" v-if="modalAddSpot">
      <UserLocationAddSpot :id="this.idAddSpot" @close="modalAddSpot = !modalAddSpot" />
    </div>

    <form>
      <div class="container-avatar-user">
        <img v-if="formDate.photo" :src="formDate.photo" />
        <img v-else src="@/assets/img/avatar.png" />
        {{ formDate.name }}
        <router-link class="link-update-photo" :to="{ name: 'user-location-photo-edit', params: { id: this.id } }">
          <img src="@/assets/icones/camera.svg" />
        </router-link>
      </div>

      <row-component class="mt-3">
        <row-col-component :class-row-col="'col-xxl-9 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
          <label>Nome do Usuário:</label>
          <input-component :type-input="'text'" v-model="formDate.name" />
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-2 mt-4'">
          <label>Sexo:</label>

          <input type="radio" id="feminino" name="radio" v-model="formDate.sexo" value="1" />
          <label for="feminino">Feminino</label>

          <input type="radio" id="masculino" name="radio" v-model="formDate.sexo" value="2" />
          <label for="masculino">Masculino</label>
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
          <label>Login (Email):</label>
          <input-component :type-input="'email'" v-model="formDate.email" />
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
          <label>Status:</label>
          <multiselect
            v-model="formDate.status"
            :searchable="true"
            :options="selectActiveInactive"
            :noResultsText="this.$store.state.var.selectNotSearch"
          />
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
          <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit" />
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
          <button-add style="width: 100%" :itens="buttonAdd"></button-add>
        </row-col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import { ACTION_MESSAGES } from '@/configs/messages';
import UserLocationAddSpot from '@/views/create/UserLocationAddSpot.vue';

export default {
  created() {
    this.$store.dispatch('updateNamePage', 'Usuário Local');
    this.$store.commit('PRELOADER', true);
    this.loadUserLocation();
  },

  name: 'UserLocationUpdate',

  components: {
    PainelComponent,
    ErrorComponent,
    RowColComponent,
    RowComponent,
    InputComponent,
    Multiselect,
    ButtonAdd,
    ButtonOne,
    UserLocationAddSpot
  },

  props: { id: { required: true } },

  data() {
    return {
      switches: false,
      buttonAdd: {
        route: '/user-location/search',
        icon: 'jump_left',
        name: 'Voltar',
        tooltip: 'Voltar'
      },

      buttonOne: {
        type: 'button',
        icon: 'floppy',
        name: 'Salvar',
        tooltip: 'Salvar'
      },

      buttonTwo: {
        type: 'button',
        icon: 'plus',
        tooltip: 'Adicionar'
      },

      buttonThree: {
        type: 'button',
        icon: 'location-add',
        tooltip: 'Adicionar Localização'
      },

      buttonCheck: {
        create: false
      },

      formDate: {
        name: '',
        email: '',
        email_verified_at: '',
        photo: '',
        status: '',
        sexo: ''
      },

      modalAddSpot: false,
      idAddSpot: '',

      username: '',

      errors: [],
      showError: false,
      sendForm: false
    };
  },

  methods: {
    routeAdd() {
      this.$router.push({ name: 'user-location-create' });
    },

    openModalAddSpot(id) {
      this.idAddSpot = id;
      this.modalAddSpot = !this.modalAddSpot;
    },

    loadUserLocation() {
      this.$store
        .dispatch('loadUserLocation', this.id)
        .then(r => {
          this.checkACL();
          this.formDate = r;
          this.username = r.name;
          this.formDate.updatepassword = false;
          this.$store.dispatch('loadSelectGroupUser');
          this.$store.dispatch('loadSelectCompany');
        })
        .catch(() => {
          this.$store.commit('addToast', ACTION_MESSAGES.errorDefault);
        });
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('updateUserLocation', this.formDate)
          .then(() => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            this.loadUserLocation();
            this.$store.commit('addToast', ACTION_MESSAGES.updateDefault);
          })
          .catch(r => {
            this.showError = true;
            this.sendForm = false;
            this.errors = r.response.data.errors;
            this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault);
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 18)
        .then(r => {
          if (r.data.indexOf('user-location-edit') !== -1) {
            this.$store.commit('PRELOADER', false);

            if (r.data.indexOf('user-location-create') !== -1) {
              this.buttonCheck.create = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    }
  },

  computed: {
    selectActiveInactive() {
      return this.$store.state.var.selectActiveInactive;
    }
  }
};
</script>

<style scoped>
.modal-add-spot {
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: fixed;
  overflow: hidden;
  place-items: center;
  background-color: rgb(0, 0, 0, 0.7);
}

.modal-add-spot > div {
  width: 80%;
  height: 85%;
}

.link-update-photo {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 6%;
}

.container-avatar-user {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  justify-content: flex-start;
  height: 130px;
  width: 100%;
}

.container-avatar-user > img {
  border-radius: 50%;
  margin-right: 15px;
  width: 100px;
}

.add-button {
  top: 0;
  right: 0;
  margin: 2%;
  position: absolute;
}

.location-add-button {
  top: 0;
  right: 0;
  margin: 2% 5%;
  position: absolute;
}

@media (max-width: 1280px) {
  .link-update-photo {
    left: 10%;
  }

  .location-add-button {
    right: 25px;
  }
}

@media (max-width: 991px) {
  .container-avatar-user {
    margin: 5% 0;
    flex-direction: column;
    align-items: center;
  }

  .link-update-photo {
    right: 10%;
  }
}

@media (max-width: 428px) {
  .link-update-photo {
    top: 55%;
    left: 30%;
  }

  .location-add-button {
    right: 10%;
  }
}
</style>
