<template>
  <painel-component :title="'Editar'">
    <button-one v-if="buttonCheck.create" class="add-button" :itens="buttonTwo" @click="routeAdd" />

    <div class="container-tabs mt-5">
      <div class="card tabs">
        <input checked id="tab-1" type="radio" class="tab tab-selector" name="tab" />
        <label for="tab-1" class="tab tab-primary">
          <i><img src="@/assets/icones/info_circle.svg" alt="" /></i>
          <span>Informações</span>
        </label>

        <input id="tab-2" type="radio" class="tab tab-selector" name="tab" />
        <label for="tab-2" class="tab tab-success">
          <i><img src="@/assets/icones/star.svg" alt="" /></i>
          <span>Obituário</span>
        </label>

        <div class="tabsShadow"></div>
        <div class="glider"></div>
        <section class="content">
          <div class="item" id="content-1">
            <form>
              <row-component>
                <row-col-component
                  :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                >
                  <label>Nome:</label>
                  <input-component disabled :type-input="'text'" v-model="formDate.name" />
                </row-col-component>

                <row-col-component
                  :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                >
                  <label>Status:</label>
                  <multiselect
                    disabled
                    v-model="formDate.status"
                    :searchable="true"
                    :options="selectActiveInactive"
                    :noResultsText="this.$store.state.var.selectNotSearch"
                  />
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                  :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                >
                  <label>Empresa:</label>
                  <Multiselect
                    disabled
                    label="name"
                    v-model="formDate.company_id"
                    :options="selectCompanyRestrict"
                    :searchable="true"
                    :noResultsText="this.$store.state.var.selectNotSearch"
                  >
                    <template v-slot:singlelabel="{ value }">
                      <div class="multiselect-single-label">
                        <img alt="" class="character-label-icon" :src="value.icon" />
                        {{ value.name }}
                      </div>
                    </template>

                    <template v-slot:option="{ option }">
                      <img alt="" class="character-option-icon" :src="option.icon" />
                      {{ option.name }}
                    </template>
                  </Multiselect>
                </row-col-component>

                <row-col-component
                  :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                >
                  <label>Tempo exibição em segundos:</label>
                  <input-component disabled :type-input="'number'" v-model="formDate.time" />
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                  :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                >
                  <label>Endereço:</label>
                  <input-component disabled :type-input="'text'" v-model="formDate.address" />
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                  :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                >
                  <label>Nome telefone:</label>
                  <input-component disabled :type-input="'text'" v-model="formDate.title_one" />
                </row-col-component>

                <row-col-component
                  :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                >
                  <label>Telefone:</label>
                  <input-component
                    disabled
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    :type-input="'text'"
                    v-model="formDate.phone_one"
                  />
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                  :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                >
                  <button-add style="width: 100%" :itens="buttonAdd" />
                </row-col-component>
              </row-component>
            </form>
          </div>

          <div class="item" id="content-2">
            <div
              class="container-obituary mt-5"
              v-for="(data, index) in this.formDate.obituary_rom_itens"
              :key="index"
            >
              <form>
                <row-component>
                  <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Nome:</label>
                    <input-component disabled :type-input="'text'" v-model="data.name" />
                  </row-col-component>
                </row-component>

                <row-component>
                  <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Data nascimento:</label>
                    <input-component
                      disabled
                      v-mask="['##/##/####']"
                      :type-input="'text'"
                      v-model="data.birth_date"
                    />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Data falecimento:</label>
                    <input-component
                      disabled
                      v-mask="['##/##/####']"
                      :type-input="'text'"
                      v-model="data.death_date"
                    />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Data velório:</label>
                    <input-component
                      disabled
                      v-mask="['##/##/####']"
                      :type-input="'text'"
                      v-model="data.funeral_date"
                    />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Hora velório:</label>
                    <input-component
                      disabled
                      v-mask="['##:##']"
                      :type-input="'text'"
                      v-model="data.funeral_hour"
                    />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Data sepultamento:</label>
                    <input-component
                      disabled
                      v-mask="['##/##/####']"
                      :type-input="'text'"
                      v-model="data.burial_date"
                    />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Hora sepultamento:</label>
                    <input-component
                      disabled
                      v-mask="['##:##']"
                      :type-input="'text'"
                      v-model="data.burial_hour"
                    />
                  </row-col-component>
                </row-component>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonAdd from '@/components/buttons/ButtonAdd';

import { mask } from 'vue-the-mask';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
  created() {
    this.loadObituaryApp();
    this.$store.commit('PRELOADER', true);
    this.$store.dispatch('loadSelectCompanyRestrict');
    this.$store.dispatch('updateNamePage', 'App Obituário');
  },

  props: {
    id: { required: true }
  },

  name: 'ObituaryAppShow',
  components: {
    PainelComponent,
    RowColComponent,
    RowComponent,
    InputComponent,
    Multiselect,
    ButtonAdd
  },

  directives: { mask },

  data() {
    return {
      buttonAdd: {
        route: '/obituary-app/search',
        icon: 'jump_left',
        name: 'Voltar',
        tooltip: 'Voltar'
      },

      buttonTwo: {
        icon: 'plus',
        type: 'button',
        name: '',
        tooltip: 'Adicionar'
      },

      buttonCheck: {
        create: false
      },

      formDate: {
        company_id: '',
        name: '',
        time: '',
        type: '',
        status: '',
        code_url: '',
        address: '',
        title_one: '',
        phone_one: '',
        urlPreview: '',
        obituary_rom_itens: []
      }
    };
  },

  methods: {
    routeAdd() {
      this.$router.push({ name: 'obituary-app-create' });
    },

    loadObituaryApp() {
      this.$store
        .dispatch('loadObituaryApp', this.id)
        .then(r => {
          this.formDate = r;
          this.checkACL();
        })
        .catch(() => {
          this.$store.commit('addToast', ACTION_MESSAGES.errorDefault);
        });
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 19)
        .then(r => {
          if (r.data.indexOf('app-obituary-room-search') !== -1) {
            this.$store.commit('PRELOADER', false);

            if (r.data.indexOf('app-obituary-room-create') !== -1) {
              this.buttonCheck.create = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    }
  },

  computed: {
    me() {
      return this.$store.state.auth.me;
    },

    selectCompanyRestrict() {
      return this.$store.state.var.selectCompanyRestrict;
    },

    selectActiveInactive() {
      return this.$store.state.var.selectActiveInactive;
    }
  }
};
</script>

<style src="@/assets/css/tabs.css"></style>

<style scoped>
.glider,
.tabs > input[type='radio'] + label {
  width: 50%;
}

#tab-2:checked ~ .glider {
  left: 50%;
}

.add-button {
  top: 0;
  right: 0;
  margin: 2%;
  position: absolute;
}

.container-obituary {
  padding: 15px;
  margin: 15px 0;
  background: #e7e7e7;
}

.modal-add-obituary-room {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.5);
}

.container-obituary-room {
  width: 80%;
  height: auto;
  position: relative;
}

.container-obituary-room .btn-close {
  top: 0;
  right: 0;
  margin: 1%;
  cursor: pointer;
  position: absolute;
}

@media (max-width: 428px) {
  .container-obituary-room {
    width: 95%;
  }

  .container-obituary-room .btn-close {
    margin: 3%;
  }

  .add-button {
    top: 10px;
  }
}
</style>
