let action_messages = {
  playerSynchronize: {
    title: 'Sucesso!',
    type: 'success',
    time: 2500,
    message: 'Player foi sincronizado com sucesso'
  },

  playerRestartApp: {
    title: 'Sucesso!',
    type: 'success',
    time: 2500,
    message: 'Aplicativo foi reiniciado com sucesso'
  },

  playerRestartDevice: {
    title: 'Sucesso!',
    type: 'success',
    time: 2500,
    message: 'Player foi reiniciado com sucesso'
  },

  searchAlert: {
    title: 'Oops!',
    type: 'info',
    time: 2500,
    message: 'Nenhum resultado encontrado!'
  },

  postDefault: {
    title: 'Sucesso!',
    type: 'success',
    time: 2500,
    message: 'Cadastro efetuado com sucesso'
  },

  updateDefault: {
    title: 'Sucesso!',
    type: 'success',
    time: 2500,
    message: 'Atualização efetuada com sucesso'
  },

  deleteCleanFolder: {
    title: 'Deletado!',
    type: 'info',
    time: 2500,
    message: 'Pasta Esvaziada com sucesso!'
  },

  deleteDefault: {
    title: 'Deletado!',
    type: 'info',
    time: 2500,
    message: 'Cadastro deletado com sucesso'
  },

  delete: {
    title: 'Deletado!',
    type: 'info',
    time: 2500,
    message: 'Deletado com sucesso'
  },

  deleteErrorDefault: {
    title: 'Oops!',
    type: 'error',
    time: 2500,
    message: 'Erro ao deletar!'
  },

  addSchedulingDate: {
    title: 'Oops!',
    type: 'error',
    time: 2500,
    message: 'Não permitido, a data já está cadastrada!'
  },

  deleteErrorMe: {
    title: 'Aviso!',
    type: 'info',
    time: 2500,
    message: 'Você não pode excluir o próprio usuário!'
  },

  deleteErrorMeGroup: {
    title: 'Aviso!',
    type: 'info',
    time: 2500,
    message: 'Você não pode excluir o próprio grupo!'
  },

  errorDefault: {
    title: 'Oops!',
    type: 'error',
    time: 2500,
    message: 'Erro desconhecido!'
  },

  errorFormDefault: {
    title: 'Oops!',
    type: 'error',
    time: 2500,
    message: 'Seu formulário contém erros, por favor verificar'
  },

  login: {
    title: 'Bem-Vindo!',
    type: 'success',
    time: 2500,
    message: 'Login efetuado com sucesso'
  },

  loginError: {
    title: 'Oops!',
    type: 'error',
    time: 2500,
    message: 'Senha ou email inválido'
  },

  logout: {
    title: 'Até Mais!',
    type: 'success',
    time: 2500,
    message: 'Logout efetuado com sucesso'
  }
};

export let ACTION_MESSAGES = action_messages;
