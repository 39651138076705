export default {
    LOAD_USERS_LOCATIONS(state, user_location) {
        state.items = user_location;
    },

    LOAD_USERS_LOCATIONS_ADD_LOCATION(state, user_location_spot) {
        state.items_add_spot = user_location_spot;
    },

    UPDATE_SEARCH_PARAMS_USERS_LOCATIONS(state,params){
        state.searchParams = params
    }
}