<template>

    <button :class="['btn btn-secondary ButtonOne']" :tooltip="itens.tooltip" :type="itens.type" @click="submit">
        <i class="icon"><img v-if="itens.icon" :src="require(`@/assets/icones/${itens.icon}.svg`)"></i>
        {{ itens.name }}
    </button>

</template>

<script>

export default {
    name: 'ButtonOne',

    props: {
        itens: {
            "type": Object,
            "default": []
        },
    },

    data() {
        return {
            buttonLoader: false
        }
    },

    methods: {
        submit() {
            if (this.loderButton) {
                this.buttonLoader == true
            }
            this.$emit('submit')
        }
    }
}

</script>

<style scoped>
.ButtonOne {
    line-height: 20px;
    /* min-width: 115px; */
    min-width: 42px;
    height: 42px;
    background: #FF7700  !important;
    border-radius: 5px;
    border: 0;
    color: #fff  !important;
    cursor: pointer;
    transition: background-color 0.5s ease-out
}

.ButtonOne:active,
.ButtonOne:focus,
.ButtonOne:hover,
.ButtonOne:focus-visible {
    background-color: #d67c1c  !important;
    color: white  !important;
    /* border-radius: 30px; */
    border-color: #d67c1c;
    box-shadow: none !important;
}

.ButtonOne:not([disabled]):hover,
.ButtonOne:not([disabled]):active {
    background-color: #d67c1c !important;
    color: white;
}

i.icon img {
    vertical-align: bottom;
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(338deg) brightness(110%) contrast(101%);
}

.preloderSend {
    padding-right: 40px;
}

.preloderSend:after {
    content: "";
    position: absolute;
    animation: load 3s infinite;
    margin-left: 4px;
}

@keyframes load {
    0% {
        content: "";
    }

    33% {
        content: ".";
    }

    66% {
        content: "..";
    }

    99% {
        content: "...";
    }
}
</style>