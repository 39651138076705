<template>
  <painel-component :title="'Adicionar'">
    <ErrorComponent :errors="errors" v-if="showError" />

    <div id="qr-code-full-region"></div>

    <div class="w-100 my-0 mx-auto">
      <button class="stop-cam-btn" v-if="stopCam" @click="stopScan">
        <img src="@/assets/icones/camera_noflash_alt.svg" alt="" />
      </button>
    </div>

    <form>
      <row-component>
        <row-col-component
          :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
          v-if="show_log"
        >
          <label>Ativar Logs:</label>

          <input v-model="formDate.log" type="radio" name="radio" id="nao" value="1" />
          <label for="nao">Não</label>

          <input v-model="formDate.log" type="radio" name="radio" id="sim" value="2" />
          <label for="sim">Sim</label>
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component
          :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 input-qr-code-desktop'"
          :class="{ formError: errors.token }"
        >
          <label>Token:</label>
          <input-component
            class="input_uppercase"
            :type-input="'text'"
            v-model="formDate.token"
            :maxlength="6"
          />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 input-qr-code-mobile'"
          :class="{ formError: errors.token }"
        >
          <label>Token:</label>
          <label class="input-select-photo-label">
            <span @click="onCamera" class="input-photo-icone"
              ><img src="@/assets/icones/camera.svg"
            /></span>
            <input-component class="input_uppercas" :type-input="'text'" v-model="formDate.token" />
          </label>
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.name }"
        >
          <label>Nome:</label>
          <input-component :type-input="'text'" v-model="formDate.name" />
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component
          :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.type_midia_id }"
        >
          <label>Tipo de Mídia:</label>
          <Multiselect
            :searchable="true"
            :noResultsText="this.$store.state.var.selectNotSearch"
            :options="selectTypeMediaPlayer"
            v-model="formDate.type_midia_id"
          />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.force_orientation }"
        >
          <label>Forçar Orientação:</label>
          <Multiselect
            :searchable="true"
            :noResultsText="this.$store.state.var.selectNotSearch"
            :options="selectNoYes"
            v-model="formDate.force_orientation"
          />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.segmento_id }"
        >
          <label>Segmento:</label>
          <Multiselect
            :searchable="true"
            :noResultsText="this.$store.state.var.selectNotSearch"
            :options="selectSegmentPlayer"
            v-model="formDate.segmento_id"
          />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.spot_id }"
        >
          <label>Localização:</label>
          <Multiselect
            :searchable="true"
            :noResultsText="this.$store.state.var.selectNotSearch"
            label="name"
            :options="selectSpot"
            v-model="formDate.spot_id"
          />
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component
          :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.orientation }"
        >
          <label>Resolução em Pixel:</label>
          <Multiselect
            label="name"
            v-model="formDate.orientation"
            :options="selectResolutionPlayer"
            :searchable="true"
            @select="selectResolution(formDate.orientation)"
            :noResultsText="this.$store.state.var.selectNotSearch"
          >
            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="character-label-icon" :src="value.icon" /> {{ value.name }}
              </div>
            </template>

            <template v-slot:option="{ option }">
              <img class="character-option-icon" :src="option.icon" /> {{ option.name }}
            </template>
          </Multiselect>
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.width }"
        >
          <label>Largura(Pixel):</label>
          <input-component
            :disabled="
              formDate.orientation != 3 && formDate.orientation != 6 && formDate.orientation != 9
            "
            v-model="formDate.width"
            :type-input="'text'"
          />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
          :class="{ formError: errors.heigth }"
        >
          <label>Altura(Pixel):</label>
          <input-component
            :disabled="
              formDate.orientation != 3 && formDate.orientation != 6 && formDate.orientation != 9
            "
            v-model="formDate.heigth"
            :type-input="'text'"
          />
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
        >
          <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit" />
        </row-col-component>

        <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
        >
          <button-add style="width: 100%" :itens="buttonAdd"></button-add>
        </row-col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';

import { Html5Qrcode } from 'html5-qrcode';

import { ACTION_MESSAGES } from '@/configs/messages';

export default {
  created() {
    this.$store.dispatch('updateNamePage', 'Player');
    this.$store.dispatch('loadSelectResolutionPlayer');
    this.$store.dispatch('loadSelectTypeMediaPlayer');
    this.$store.dispatch('loadSelectSegmentPlayer');
    this.$store.dispatch('loadSelectSpot');
    this.$store.commit('PRELOADER', true);
    this.checkACL();
    this.loadPlayerShowLog();
  },

  name: 'PlayerCreate',
  components: {
    PainelComponent,
    ErrorComponent,
    RowColComponent,
    RowComponent,
    InputComponent,
    Multiselect,
    ButtonOne,
    ButtonAdd
  },

  data() {
    return {
      buttonAdd: {
        route: '/player/search',
        icon: 'jump_left',
        name: 'Voltar',
        tooltip: 'Voltar'
      },

      buttonOne: {
        icon: 'plus',
        type: 'button',
        name: 'Adicionar',
        tooltip: 'Adicionar'
      },

      formDate: {
        token: '',
        name: '',
        type_midia_id: '',
        segmento_id: '',
        force_orientation: '',
        spot_id: '',
        width: '',
        heigth: '',
        orientation: '',
        log: '1'
      },

      show_log: '',

      config: {
        fps: 10,
        qrbox: 250
      },

      stopCam: false,

      errors: [],
      showError: false,
      sendForm: false
    };
  },

  methods: {
    onCamera() {
      const html5QrCode = new Html5Qrcode('qr-code-full-region');
      const qrCodeSuccessCallback = decodedText => {
        html5QrCode.stop().then(() => {
          this.formDate.token = decodedText;
          this.stopCam = false;
        });
      };
      this.stopCam = true;
      html5QrCode.start({ facingMode: 'environment' }, this.config, qrCodeSuccessCallback);
    },

    stopScan() {
      const html5QrCode = new Html5Qrcode('qr-code-full-region');
      html5QrCode.clear();
      this.stopCam = false;
    },

    loadPlayerShowLog() {
      this.$store.dispatch('loadPlayerShowLog').then(r => {
        this.show_log = r.show_log;
      });
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('storePlayer', this.formDate)
          .then(r => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            this.$store.commit('addToast', ACTION_MESSAGES.postDefault);
            this.$router.push({ name: 'player-edit', params: { id: r.data } });
          })
          .catch(r => {
            this.showError = true;
            this.sendForm = false;
            this.errors = r.response.data.errors;
            this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault);
          });
      }
    },

    selectResolution(value) {
      switch (value) {
        case 1:
          this.formDate.width = '1280';
          this.formDate.heigth = '720';
          break;
        case 2:
          this.formDate.width = '1920';
          this.formDate.heigth = '1080';
          break;
        case 3:
          this.formDate.width = '';
          this.formDate.heigth = '';
          break;
        case 4:
          this.formDate.width = '720';
          this.formDate.heigth = '1280';
          break;
        case 5:
          this.formDate.width = '1080';
          this.formDate.heigth = '1920';
          break;
        case 6:
          this.formDate.width = '';
          this.formDate.heigth = '';
          break;
        case 7:
          this.formDate.width = '720';
          this.formDate.heigth = '1280';
          break;
        case 8:
          this.formDate.width = '1080';
          this.formDate.heigth = '1920';
          break;
        case 9:
          this.formDate.width = '';
          this.formDate.heigth = '';
          break;
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 6)
        .then(r => {
          if (r.data.indexOf('player-create') !== -1) {
            this.$store.commit('PRELOADER', false);
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    }
  },

  computed: {
    selectResolutionPlayer() {
      return this.$store.state.var.selectResolutionPlayer;
    },

    selectTypeMediaPlayer() {
      return this.$store.state.var.selectTypeMediaPlayer;
    },

    selectSegmentPlayer() {
      return this.$store.state.var.selectSegmentPlayer;
    },

    selectNoYes() {
      return this.$store.state.var.selectNoYes;
    },

    selectSpot() {
      return this.$store.state.var.selectSpot;
    }
  }
};
</script>

<style scoped>
.input_uppercase {
  text-transform: uppercase;
}

.input-qr-code-mobile {
  display: none;
}

.input-qr-code-desktop {
  display: block;
}

.stop-cam-btn {
  height: 42px;
  width: 43px;
  border-radius: 5px;
  text-align: center;
  padding: 0.4rem;
  outline: none;
  border: 0;
  margin: 5px 0;
  background-color: #f97f2c;
}

.stop-cam-btn > img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(199deg) brightness(105%) contrast(100%);
}

@media (max-width: 991px) {
  .input-qr-code-mobile {
    display: block;
  }

  .input-qr-code-desktop {
    display: none;
  }
}
</style>
