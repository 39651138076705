import axios from 'axios';
import { URL_BASE } from '@/configs/constants';
const RESOURCE = 'content-file';

export default {
  loadContentsFiles(context, params) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}`, { params })
        .then(response => {
          context.commit('LOAD_CONTENT_FILE', response.data);
          return resolve(response.data);
        })
        .catch(() => {
          return reject();
        })
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  storeContentFile(context, formData) {
    let config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}${RESOURCE}`, formData, config)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors));
    });
  },

  loadContentFile(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  updateContentFile(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  destroyContentFile(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors));
    });
  },

  addSchedulingFile(context, fileData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}content-file-add-scheduling`, fileData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  loadScheduling(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}content-file-get-scheduling/${id}`)
        .then(response => {
          context.commit('PRELOADER', response.data);
          resolve(response.data);
        })
        .catch(e => reject(e))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  updateSchedulingFile(context, fileData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}content-file-update-scheduling/${fileData.id}`, fileData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  deleteSchedulingFile(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}content-file-delete-scheduling/${id}`)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors));
    });
  }
};
