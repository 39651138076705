<template>
  <painel-component :title="'Selecionar Conteúdooo'">
    <ErrorComponent :errors="errors" v-if="showError" />
    <div class="window-modal" v-if="modalFile">
      <CampaignFileSearch
        :id="id"
        :folder_id="folder_id"
        @close-modal-file-submit="closeFileModal"
        @close-modal-file="closeFileModal"
      />
    </div>

    <div @click="$emit('close-modal-content')" class="btn-close"></div>
    <row-component>
      <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-10 col-sm-10 col-10'">
        <label>Nome da Pasta:</label>
        <input-component :type-input="'text'" v-model="formDate.name" />
      </row-col-component>

      <row-col-component
        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 p-0 mt-4'"
      >
        <button-one :itens="buttonOne" @submit="submit" />
      </row-col-component>
    </row-component>

    <div class="content-file-explorer">
      <div class="file-content" v-for="(folder, index) in campaign_content.data" :key="index">
        <div class="file-link-div" @click="openModalFile(folder.id)" :tooltip="folder.name">
          <img src="@/assets/icones/folder_closed.svg" />
          <p>{{ folder.name }}</p>
        </div>
      </div>
    </div>

    <button-pagination
      class="pagination-settings button-add-bottom"
      :pagination="campaign_content"
      :offset="3"
      @paginate="loadCampaingsContents"
    />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import { ACTION_MESSAGES } from '@/configs/messages';
import CampaignFileSearch from '@/views/search/CampaignFileSearch';

export default {
  created() {
    this.loadCampaingsContents(1);
  },

  name: 'CampaignContentSearch',
  components: {
    PainelComponent,
    ErrorComponent,
    RowColComponent,
    RowComponent,
    InputComponent,
    ButtonOne,
    ButtonPagination,
    CampaignFileSearch
  },

  props: {
    id: { required: true }
  },

  emits: ['close-modal-content'],

  data() {
    return {
      buttonOne: {
        icon: 'search',
        tooltip: 'Buscar',
        type: 'button'
      },

      formDate: {
        id: this.id,
        name: '',
        company_id: ''
      },

      folder_id: '',
      modalFile: false,

      errors: [],
      showError: false,
      sendForm: false
    };
  },

  methods: {
    submit() {
      this.loadCampaingsContents(1);
      this.searchSubmit = true;
    },

    loadCampaingsContents(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadCampaingsContents', { ...this.params, page })
          .then(r => {
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data == '') {
                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert);
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    closeFileModal(n) {
      this.modalFile = !this.modalFile;
      if (n == 1) {
        this.modalFile = !this.modalFile;
        this.$emit('close-modal-content', 1);
      }
    },

    openModalFile(id) {
      this.folder_id = id;
      this.modalFile = !this.modalFile;
    }
  },

  computed: {
    campaign_content() {
      return this.$store.state.campaign_content.items;
    },

    params() {
      return {
        page: this.campaign_content.current_page,
        id: this.formDate.id,
        name: this.formDate.name
      };
    }
  }
};
</script>

<style scoped>
.btn-close {
  top: 0;
  right: 0;
  margin: 2%;
  cursor: pointer;
  position: absolute;
}

.content-file-explorer {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  min-height: 100%;
  border-radius: 0 0 10px 10px;
}

.file-content {
  font-size: 14px;
  position: relative;
  display: flex;
  height: fit-content;
  width: 105px;
  margin: 5px 10px;
  align-items: center;
  flex-direction: column;
  word-wrap: break-word;
  text-align: center;
}

.file-link-div {
  height: 100%;
  width: 100%;
}

.file-link-div > img {
  height: 80px;
}

.file-link-div p {
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-link-div:hover {
  cursor: pointer;
  background-color: rgb(192, 192, 192, 0.4);
}

.pagination-settings {
  right: 0;
  position: absolute;
  bottom: 0;
  margin: 0 30px;
}

.window-modal {
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  display: grid;
  overflow: auto;
  position: fixed;
  place-items: center;
}

.window-modal > div {
  width: 70%;
  height: 75%;
}
</style>

<style scoped>
@media (max-width: 1366px) {
  .window-modal > div {
    width: 90%;
    height: 95%;
    overflow: auto;
  }
}

@media (max-width: 1280px) {
  .button-add-bottom {
    bottom: unset;
  }
}

@media (max-width: 991px) {
  .file-content p {
    -webkit-line-clamp: 2;
  }

  .window-modal > div {
    width: 95%;
    height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .button-add-bottom {
    bottom: unset;
  }
}

@media (max-width: 768px) {
  .content-file-explorer {
    justify-content: space-around;
  }

  .file-content {
    width: 140px;
    margin: 5px 25px;
  }

  .button-add-bottom {
    bottom: unset;
  }
}

@media (max-width: 445px) {
  .file-content {
    text-align: center;
    align-items: center;
    width: 105px;
    height: 110px;
    margin: 5px 15px;
  }

  .file-content img {
    width: 60%;
  }

  .button-add-bottom {
    bottom: unset;
  }
}
</style>
