<template>
  <ModalComponent
    @submit-logout="logout"
    @close-modal="openModal"
    :visible="visible"
    :message="message"
  />

  <div class="menuContainer" :class="{ openMenuContainer: openMenu }">
    <div class="logoContainer">
      <router-link to="/">
        <img src="@/assets/img/logo.png" alt="Methoduz" />
      </router-link>
    </div>

    <div class="userContainer" @click="dropMenu.navOne = !dropMenu.navOne">
      <div class="avatar">
        <img v-if="me.photo" :src="me.photo" alt="" />
        <img v-else src="@/assets/img/avatar.png" alt="" />
        <p>
          {{ me.name }}
          <i class="iconMenu">
            <img v-if="!dropMenu.navOne" src="@/assets/icones/chevron_right.svg" alt="" />
            <img v-else src="@/assets/icones/chevron_down.svg" alt="" />
          </i>
        </p>
      </div>

      <transition name="slide">
        <ul class="links submenu userMenu" v-if="dropMenu.navOne">
          <li>
            <router-link :to="{ name: 'user-photo-edit' }" @click="$emit('menuResp')">
              <i class="icon-two">
                <img src="@/assets/icones/picture.svg" alt="" />
              </i>
              Alterar Foto
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'user-password-edit' }" @click="$emit('menuResp')">
              <i class="icon-two">
                <img src="@/assets/icones/lock_open.svg" alt="" /> </i
              >Alterar Senha
            </router-link>
          </li>
          <li>
            <a href="#" @click.prevent="openModal" @click="$emit('menuResp')">
              <i class="icon-two"><img src="@/assets/icones/enter_alt.svg" alt="" /></i>
              Sair
            </a>
          </li>
        </ul>
      </transition>
    </div>

    <div class="linkContainer" v-if="showItensMenu.pageAplicativo">
      <ul>
        <li
          @click="$emit('menuResp')"
          v-if="checkPermissionMenu(linkMenus.menuAplicativo.pagePermissions)"
        >
          <router-link :to="linkMenus.menuAplicativo.route" class="linkroute_two">
            <i class="icon">
              <img :src="require(`@/assets/icones/${linkMenus.menuAplicativo.icon}.svg`)" alt="" />
            </i>
            {{ linkMenus.menuAplicativo.name }}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="linkContainer" v-if="showItensMenu.pageConteudo">
      <ul>
        <li
          @click="$emit('menuResp')"
          v-if="checkPermissionMenu(linkMenus.menuConteudo.pagePermissions)"
        >
          <router-link :to="linkMenus.menuConteudo.route" class="linkroute_two">
            <i class="icon">
              <img :src="require(`@/assets/icones/${linkMenus.menuConteudo.icon}.svg`)" alt="" />
            </i>
            {{ linkMenus.menuConteudo.name }}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="linkContainer" v-if="showItensMenu.pageCampanha">
      <ul>
        <li
          @click="$emit('menuResp')"
          v-if="checkPermissionMenu(linkMenus.menuCampanha.pagePermissions)"
        >
          <router-link :to="linkMenus.menuCampanha.route" class="linkroute_two">
            <i class="icon">
              <img :src="require(`@/assets/icones/${linkMenus.menuCampanha.icon}.svg`)" alt="" />
            </i>
            {{ linkMenus.menuCampanha.name }}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="linkContainer" v-if="showItensMenu.pageEmpresa">
      <ul>
        <li
          @click="$emit('menuResp')"
          v-if="checkPermissionMenu(linkMenus.menuEmpresa.pagePermissions)"
        >
          <router-link :to="linkMenus.menuEmpresa.route" class="linkroute_two">
            <i class="icon">
              <img :src="require(`@/assets/icones/${linkMenus.menuEmpresa.icon}.svg`)" alt="" />
            </i>
            {{ linkMenus.menuEmpresa.name }}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="linkContainer" v-if="showItensMenu.pageLocal">
      <ul>
        <li @click="dropMenu.navLocal = !dropMenu.navLocal">
          <a href="#" class="linkroute_two">
            <i class="icon">
              <img :src="require(`@/assets/icones/${linkMenus.menuLocal.icon}.svg`)" alt="" />
            </i>
            {{ linkMenus.menuLocal.name }}
          </a>
          <i class="iconMenu">
            <img v-if="!dropMenu.navLocal" src="@/assets/icones/chevron_right.svg" alt="" />
            <img v-else src="@/assets/icones/chevron_down.svg" alt="" />
          </i>
        </li>

        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navLocal">
            <div
              v-for="(link, index) in linkMenus.menuLocal.subLinks"
              :key="index"
              @click="$emit('menuResp')"
            >
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                class="linkroute"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>

    <div class="linkContainer" v-if="showItensMenu.pagePlayer">
      <ul>
        <li
          @click="$emit('menuResp')"
          v-if="checkPermissionMenu(linkMenus.menuPlayer.pagePermissions)"
        >
          <router-link :to="linkMenus.menuPlayer.route" class="linkroute_two">
            <i class="icon">
              <img :src="require(`@/assets/icones/${linkMenus.menuPlayer.icon}.svg`)" alt="" />
            </i>
            {{ linkMenus.menuPlayer.name }}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="linkContainer" v-if="showItensMenu.pageTutotial">
      <ul>
        <li
          @click="$emit('menuResp')"
          v-if="checkPermissionMenu(linkMenus.menuTutorial.pagePermissions)"
        >
          <router-link :to="linkMenus.menuTutorial.route" target="_blank" class="linkroute_two">
            <i class="icon">
              <img :src="require(`@/assets/icones/${linkMenus.menuTutorial.icon}.svg`)" alt="" />
            </i>
            {{ linkMenus.menuTutorial.name }}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="linkContainer" v-if="showItensMenu.pageCadastro">
      <ul>
        <li @click="dropMenu.navCadastro = !dropMenu.navCadastro">
          <a href="#" class="linkroute_two">
            <i class="icon">
              <img :src="require(`@/assets/icones/${linkMenus.menuCadastro.icon}.svg`)" alt="" />
            </i>
            {{ linkMenus.menuCadastro.name }}
          </a>
          <i class="iconMenu">
            <img v-if="!dropMenu.navCadastro" src="@/assets/icones/chevron_right.svg" alt="" />
            <img v-else src="@/assets/icones/chevron_down.svg" alt="" />
          </i>
        </li>

        <transition name="dropMenu">
          <div class="submenu" v-if="dropMenu.navCadastro">
            <div
              v-for="(link, index) in linkMenus.menuCadastro.subLinks"
              :key="index"
              @click="$emit('menuResp')"
            >
              <router-link
                v-if="checkPermissionMenu(link.pagePermissions)"
                :to="link.route"
                class="linkroute"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </transition>
      </ul>
    </div>
  </div>
</template>

<script>
import linkMenus from '@/configs/linkMenus';
import ModalComponent from '@/components/modal/ModalComponent';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
  created() {
    this.checkACLnav();
  },

  name: 'NavMenu',

  components: {
    ModalComponent
  },

  props: {
    openMenu: {
      required: true,
      type: Boolean
    }
  },

  emits: ['menuResp'],

  data() {
    return {
      permissionsPage: [],
      active: false,
      dropMenu: {
        navOne: false,
        // navAplicativo: false,
        // navCampanha: false,
        // navConteudo: false,
        // navEmpresa: false,
        navLocal: false,
        // navPlayer: false,
        navCadastro: false
      },
      linkMenus,
      activeLink: false,
      showItensMenu: {
        pageAplicativo: false,
        pageCampanha: false,
        pageConteudo: false,
        pageEmpresa: false,
        pageLocal: false,
        pagePlayer: false,
        pageCadastro: false,
        pageTutotial: false
      },

      visible: false,
      message: ''
    };
  },

  methods: {
    openModal() {
      this.visible = !this.visible;
      this.message = `Deseja realmente sair , ${this.me.name}`;
    },

    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$store.commit('addToast', ACTION_MESSAGES.logout);
        this.visible = !this.visible;
        setTimeout(() => this.$router.push({ name: 'login' }), 2200);
      });
    },

    async checkACLnav() {
      this.$store.dispatch('ACLitensnav').then(r => {
        this.permissionsPage = r.data;
        this.permissionsPage.forEach(item => {
          if (linkMenus.menuCadastro.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageCadastro = true;
          }
          if (linkMenus.menuEmpresa.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageEmpresa = true;
          }
          if (linkMenus.menuLocal.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageLocal = true;
          }
          if (linkMenus.menuPlayer.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pagePlayer = true;
          }

          if (linkMenus.menuCampanha.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageCampanha = true;
          }

          if (linkMenus.menuConteudo.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageConteudo = true;
          }

          if (linkMenus.menuAplicativo.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageAplicativo = true;
          }

          if (linkMenus.menuTutorial.pageLinksNavCheck.includes(item)) {
            this.showItensMenu.pageTutotial = true;
          }
        });
      });
    },

    checkPermissionMenu(page) {
      let shouldSkip = false;
      this.permissionsPage.forEach(value => {
        if (shouldSkip) {
          return;
        }
        if (page.includes(value)) {
          shouldSkip = true;
        }
      });
      return shouldSkip;
    }
  },

  computed: {
    me() {
      return this.$store.state.auth.me;
    }
  }
};
</script>

<style scoped lang="scss">
.userMenu {
  margin-top: 15px;
  padding: 0 15px;
}

.menuContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 260px;
  min-height: 100vh;
  padding: 0 10px;
  overflow-x: auto;
  transition: all 0.4s linear;
}

.menuContainer .logoContainer {
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
}

.menuContainer .logoContainer img {
  margin: 22px 0 0 0;
  max-width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.menuContainer .userContainer {
  border-bottom: 1px solid #ddd;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 48px;
}

.menuContainer .userContainer:hover {
  cursor: pointer;
}

.menuContainer .userContainer .avatar img {
  max-width: 35px;
  border-radius: 50%;
  float: left;
  // margin-right: 15px;
}

.menuContainer .userContainer p {
  color: #2c2c2c;
  opacity: 0.7;
  margin: 0;
  padding-top: 5px;
  margin-left: 50px;
}

.menuContainer .submenu {
  // padding: 0 15px;
  clear: both;
  list-style-type: none;
  transform-origin: top;
  transition: transform 0.4s ease-in-out;
  overflow: hidden;
  background-color: #e3e3e3;
  width: 100%;
}

i.icon {
  margin-right: 10px;
}

.menuContainer .submenu li {
  padding: 5px 0;
}

.menuContainer .submenu li a {
  font-size: 14px;
}

.menuContainer .links a {
  display: block;
  text-decoration: none;
  color: #000;
  line-height: 1.5em;
  height: 30px;
}

.menuContainer .links a i {
  color: #686666;
  font-size: 15px;
}

.menuContainer .iconMenu {
  position: absolute;
  right: 15px;
}

.linkroute_two {
  display: block;
  padding: 10px 0;
  height: 45px;
  width: 100%;
  text-decoration: none;
  color: #000000;
}

.linkroute {
  display: block;
  padding: 10px;
  height: 45px;
  width: 100%;
  background-color: #fbfbfb;
  text-decoration: none;
  color: #000000;
}

.linkroute:hover {
  background-color: rgba(243, 143, 12, 1) !important;
  color: #ffffff;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}

// ####################################################

.linkContainer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 10px;
}

.linkContainer ul:hover {
  cursor: pointer;
}

.linkContainer ul li {
  display: flex;
  align-items: center;
  height: 46px;
  border-bottom: 1px solid #ddd;
}

.linkContainer ul li a {
  display: flex;
  align-items: center;
  color: #2c2c2c;
  text-decoration: none;
  font-size: 14px;
  opacity: 0.7;
}

.linkContainer .iconMenu {
  position: absolute;
  right: 15px;
}

.linkContainer .submenu {
  list-style-type: none;
  transform-origin: top;
  transition: transform 0.4s ease-in-out;
  overflow: hidden;
  width: 100%;
}

.activeBackground {
  background-color: rgba(243, 143, 12, 1) !important;
  color: white !important;
}

.dropMenu-enter,
.dropMenu-leave-to,
.dropMenu-enter-from {
  transform: scaleY(0);
}

.slide-enter,
.slide-leave-to,
.slide-enter-from {
  transform: scaleY(0);
}

/*
@media(max-width: 1290px) {
  .menuContainer {
    left: -260px;
  }

  .openMenuContainer {
    left: 0;
  }
}
*/

@media (max-width: 991px) {
  .menuContainer {
    left: -260px;
  }

  .openMenuContainer {
    left: 0;
  }
}

i.icon > img {
  height: 36px;
  filter: invert(38%) sepia(6%) saturate(550%) hue-rotate(353deg) brightness(95%) contrast(88%);
}
</style>
