<template>
  <painel-component :title="'Adicionar Local'">
    <ErrorComponent :errors="errors" v-if="showError" />
    <div class="btn-close" @click="$emit('close')"></div>

    <div class="container-tabs mt-3">
      <div class="card tabs">
        <input id="tab-1" type="radio" :checked="change_tab" class="tab tab-selector" name="tab" />
        <label for="tab-1" class="tab tab-primary">
          <i><img src="@/assets/icones/plus.svg" /></i>
          <span>Adicionar</span>
        </label>

        <input id="tab-2" type="radio" :checked="change_tab_two" class="tab tab-selector" name="tab" />
        <label for="tab-2" class="tab tab-success">
          <i><img src="@/assets/icones/location.svg" /></i>
          <span>Locais Adicionados</span>
        </label>

        <div class="tabsShadow"></div>
        <div class="glider"></div>
        <section class="content">
          <div class="item" id="content-1">
            <form>
              <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                  <label>Nome:</label>
                  <input-component :type-input="'text'" v-model="formDate.name" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                  <label>Empresa:</label>
                  <Multiselect
                    v-model="formDate.company_id"
                    :searchable="true"
                    label="name"
                    :options="selectCompanyRestrict"
                  >
                    <template v-slot:singlelabel="{ value }">
                      <div class="multiselect-single-label">
                        <img class="character-label-icon" :src="value.icon" />
                        {{ value.name }}
                      </div>
                    </template>

                    <template v-slot:option="{ option }">
                      <img class="character-option-icon" :src="option.icon" />
                      {{ option.name }}
                    </template>
                  </Multiselect>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-4'">
                  <button-one :itens="buttonOne" @click="submit" />
                </row-col-component>
              </row-component>
            </form>
            <row-component>
              <row-col-component
                :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2'"
                v-for="(data, index) in this.spots.data"
                :key="index"
              >
                <div class="file-content">
                  <input
                    :value="data.id"
                    v-model="formDateAdd.spots_ids"
                    type="checkbox"
                    name="thumbs"
                    :id="'thumbs' + data.id"
                  />
                  <label :for="'thumbs' + data.id">
                    <p>
                      <img src="@/assets/icones/location.svg" alt="" /> {{ data.name }} <br />
                      <img src="@/assets/icones/play_button.svg" alt="" />
                      {{ statusString(data.status) }} <br />
                    </p>
                  </label>
                </div>
              </row-col-component>
            </row-component>

            <div class="bottom-buttons">
              <button-one :disabled="sendForm" :itens="buttonTwo" @click="storeUserLocationAddLocation" />
              <button-pagination :pagination="spots" :offset="3" @paginate="loadUsersLocationsAddLocation" />
            </div>
          </div>

          <div class="item" id="content-2">
            <row-component>
              <row-col-component
                v-if="spots_add.length == 0"
                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
              >
                <div class="alert alert-secondary icon-message" role="alert">Não há nada cadastrado</div>
              </row-col-component>

              <row-col-component
                v-else
                :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2'"
                v-for="(data, index) in spots_add"
                :key="index"
              >
                <div class="file-content">
                  <p><img src="@/assets/icones/location.svg" alt="" /> {{ data.name }} <br /></p>
                  <button-delete @click="destroyUserLocationAddLocation(data.spot_id)" />
                </div>
              </row-col-component>
            </row-component>
          </div>
        </section>
      </div>
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
  created() {
    this.loadUsersLocationsAddLocation(1);
    this.$store.dispatch('loadSelectCompanyRestrict');
  },

  props: {
    id: { required: true }
  },

  name: 'UserLocationAddSpot',
  components: {
    PainelComponent,
    ErrorComponent,
    RowColComponent,
    RowComponent,
    InputComponent,
    Multiselect,
    ButtonOne,
    ButtonDelete,
    ButtonPagination
  },

  data() {
    return {
      buttonAdd: {
        route: '/spot/search',
        icon: 'jump_left',
        name: 'Voltar',
        tooltip: 'Voltar'
      },

      buttonOne: {
        icon: 'search',
        type: 'button',
        tooltip: 'Buscar'
      },

      buttonTwo: {
        icon: 'plus',
        type: 'button',
        name: 'Adicionar',
        tooltip: 'Adicionar'
      },

      formDate: {
        id: this.id,
        name: '',
        company_id: ''
      },

      formDateAdd: {
        id: this.id,
        spots_ids: []
      },

      spots: [],
      spots_add: [],

      change_tab: true,
      change_tab_two: false,

      errors: [],
      showError: false,
      sendForm: false,
      searchSubmit: false
    };
  },

  methods: {
    submit() {
      this.loadUsersLocationsAddLocation(1);
      this.searchSubmit = true;
    },

    checkedTabOne() {
      let data = this.spots_add;
      if (data.length == 0) {
        this.change_tab = !this.change_tab;
        this.change_tab_two = !this.change_tab_two;
      }
    },

    statusString(value) {
      if (value == 1) return 'Ativo';
      if (value == 2) return 'Inativo';
    },

    loadUsersLocationsAddLocation(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadUsersLocationsAddLocation', { ...this.params, page })
          .then(r => {
            this.spots = r.result;
            this.spots_add = r.spotAdd;
            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.result.data == '') {
                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert);
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    storeUserLocationAddLocation() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('storeUserLocationAddLocation', this.formDateAdd)
          .then(() => {
            this.loadUsersLocationsAddLocation(1);
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            this.$store.commit('addToast', ACTION_MESSAGES.postDefault);
            this.change_tab = !this.change_tab;
            this.change_tab_two = !this.change_tab_two;
          })
          .catch(r => {
            this.loadUsersLocationsAddLocation(1);
            this.showError = true;
            this.sendForm = false;
            this.errors = r.response.data.errors;
            this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault);
          });
      }
    },

    destroyUserLocationAddLocation(spot_id) {
      let params = {
        id: this.id,
        spot_id: spot_id
      };
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('destroyUserLocationAddLocation', params)
          .then(() => {
            this.loadUsersLocationsAddLocation(1);
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault);
            this.formDateAdd = [];
          })
          .catch(r => {
            this.loadUsersLocationsAddLocation(1);
            this.showError = true;
            this.sendForm = false;
            this.errors = r.response.data.errors;
            this.$store.commit('addToast', ACTION_MESSAGES.errorDefault);
            this.formDateAdd.spots_ids = [];
          });
      }
    }
  },

  computed: {
    me() {
      return this.$store.state.auth.me;
    },

    user_location_spot() {
      return this.$store.state.user_location.items_add_spot;
    },

    params() {
      return {
        page: this.user_location_spot.current_page,
        id: this.formDate.id,
        name: this.formDate.name,
        company_id: this.formDate.company_id
      };
    },

    selectCompanyRestrict() {
      return this.$store.state.var.selectCompanyRestrict;
    }
  }
};
</script>

<style src="@/assets/css/tabs.css"></style>
<style scoped>
.content {
  height: 65vh;
}

.tabs > input[type='radio'] + label {
  width: 50%;
}

#tab-2:checked ~ .glider {
  left: 50%;
}

.glider {
  width: 50%;
}

.btn-close {
  top: 0;
  right: 0;
  margin: 2%;
  cursor: pointer;
  position: absolute;
}

.file-content {
  width: 100%;
  height: 80px;
  display: flex;
  color: black;
  padding: 15px 10px;
  position: relative;
  align-items: center;
  background: #ebebeb;
  justify-content: space-between;
}

.file-content > img {
  height: auto;
  width: 50px;
  margin: 0 10px 0 5px;
}

.file-content p {
  font-size: 14px;
}

input[type='checkbox'][id^='thumbs'] {
  display: none;
}

.file-content label {
  padding: 0 10px;
  cursor: pointer;
  width: 100%;
}

.file-content label:before {
  background-color: grey;
  color: white;
  content: ' ';
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: 4%;
  right: 1%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 22px;
  z-index: 1;
  transition-duration: 0.4s;
  content: '✓';
  transform: scale(1);
}

.file-content input:checked + label:before {
  content: '✓';
  background-color: #2ab934;
  border: 1px solid #2ab934;
  transform: scale(1);
}

.file-content input[type='checkbox'] + label::after {
  display: none;
}

.container-paginationGroup {
  margin: unset;
}

.bottom-buttons {
  bottom: 0%;
  width: 100%;
  height: 52px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 425px) {
  .bottom-buttons {
    bottom: unset;
  }

  .mt-4 {
    margin: 0 !important;
  }
}
</style>
