import axios from 'axios';
import { URL_BASE } from '@/configs/constants';

const RESOURCE = 'app/obituary';
export default {
  loadObituarysApps(context, params) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}`, { params })
        .then(response => {
          context.commit('LOAD_OBITUARY_APP', response.data);
          return resolve(response.data);
        })
        .catch(errors => {
          return reject(errors);
        })
        .finally(() => {
          context.commit('PRELOADER', false);
        });
    });
  },

  storeObituarysApps(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}${RESOURCE}`, formData)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => {
          context.commit('PRELOADER', false);
        });
    });
  },

  loadObituaryApp(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e));
    });
  },

  updateObituaryApp(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}${RESOURCE}/${formData.app_id}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => {
          context.commit('PRELOADER', false);
        });
    });
  },

  destroyObituaryApp(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  storeObituaryAppsRoom(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}app/obituary-room-item-add`, formData)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => {
          context.commit('PRELOADER', false);
        });
    });
  },

  updateObituaryAppsRoom(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}app/obituary-room-item-add/${formData.idobituary}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => {
          context.commit('PRELOADER', false);
        });
    });
  },

  destroyObituaryAppsRoom(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}app/obituary-room-item-delete/${formData.id}/${formData.idobituary}`)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  addNewRoom(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}app/obituary-room-add`, formData)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => {
          context.commit('PRELOADER', false);
        });
    });
  },

  loadRoomItem(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}app/obituary-room-list/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e));
    });
  },

  updateRoom(context, payload) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}app/obituary-room-update/${payload.id}`, payload)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => {
          context.commit('PRELOADER', false);
        });
    });
  },

  destroyRoom(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}app/obituary-add-delete/${formData.id}/${formData.idRoom}`)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  }
};
