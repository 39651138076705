<template>
  <ModalComponent
    @submit-logout="destroyObituaryAppsRoom"
    @close-modal="closeModal"
    :visible="visible"
    :message="message"
  />

  <!-- MODAL ADD ROOM -->
  <div class="modal-add-obituary-room" v-if="modal_add_room">
    <painel-component :title="modalTitle" class="container-obituary-room">
      <ErrorComponent :errors="errors" v-if="showErrorRoom" />
      <div @click="modalAddRoom" class="btn-close"></div>
      <form>
        <row-component>
          <row-col-component
            :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.name }"
          >
            <label>Nome:</label>
            <input-component :type-input="'text'" v-model="formDataRoom.name" />
          </row-col-component>

          <row-col-component
            :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.status }"
          >
            <label>Local:</label>
            <input-component :type-input="'text'" v-model="formDataRoom.cemetery" />
          </row-col-component>
        </row-component>

        <row-component>
          <row-col-component
            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.birth_date }"
          >
            <label>Data nascimento:</label>
            <input-component
              v-mask="['##/##/####']"
              :type-input="'text'"
              v-model="formDataRoom.birth_date"
            />
          </row-col-component>

          <row-col-component
            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.death_date }"
          >
            <label>Data falecimento:</label>
            <input-component
              v-mask="['##/##/####']"
              :type-input="'text'"
              v-model="formDataRoom.death_date"
            />
          </row-col-component>

          <row-col-component
            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.funeral_date }"
          >
            <label>Data velório:</label>
            <input-component
              v-mask="['##/##/####']"
              :type-input="'text'"
              v-model="formDataRoom.funeral_date"
            />
          </row-col-component>

          <row-col-component
            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.funeral_hour }"
          >
            <label>Hora velório:</label>
            <input-component
              v-mask="['##:##']"
              :type-input="'text'"
              v-model="formDataRoom.funeral_hour"
            />
          </row-col-component>

          <row-col-component
            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.burial_date }"
          >
            <label>Data sepultamento:</label>
            <input-component
              v-mask="['##/##/####']"
              :type-input="'text'"
              v-model="formDataRoom.burial_date"
            />
          </row-col-component>

          <row-col-component
            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.burial_hour }"
          >
            <label>Hora sepultamento:</label>
            <input-component
              v-mask="['##:##']"
              :type-input="'text'"
              v-model="formDataRoom.burial_hour"
            />
          </row-col-component>
        </row-component>

        <row-component>
          <row-col-component
            :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
          >
            <label>N° Processo:</label>
            <input-component :type-input="'text'" v-model="formDataRoom.process" />
          </row-col-component>

          <row-col-component
            :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.status }"
          >
            <label>Sala:</label>
            <!-- <input-component :type-input="'text'" v-model="formDataRoom.code_roow" /> -->
            <multiselect
              v-model="formDataRoom.code_room"
              :options="roomItemData"
              label="name"
              :searchable="true"
              :noResultsText="this.$store.state.var.selectNotSearch"
            />
          </row-col-component>
        </row-component>

        <row-component>
          <row-col-component
            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
          >
            <label>Mensagem: </label>
            <input-text-area :rows="4" v-model="formDataRoom.message" />
          </row-col-component>
        </row-component>

        <row-component>
          <row-col-component
            v-if="btn_add_room"
            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
          >
            <button-one
              :disabled="sendRoom"
              style="width: 100%"
              :itens="buttonThree"
              @click="submitAddRoom"
            />
          </row-col-component>

          <row-col-component
            v-if="btn_edit_room"
            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
          >
            <button-one
              :disabled="sendRoom"
              style="width: 100%"
              :itens="buttonOne"
              @click="submitEditRoom(formDataRoom.id)"
            />
          </row-col-component>
        </row-component>
      </form>
    </painel-component>
  </div>

  <!-- MODAL NEW ROOM -->
  <div class="modal-add-obituary-room" v-if="modal_new_room">
    <painel-component :title="'Adicionar Sala'" class="container-obituary-room">
      <ErrorComponent :errors="errors" v-if="showErrorRoom" />
      <div @click="modalNewRoom" class="btn-close"></div>
      <form>
        <row-component>
          <row-col-component
            :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.name }"
          >
            <label>Código:</label>
            <input-component :type-input="'text'" v-model="code_roow" />
          </row-col-component>

          <row-col-component
            :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.name }"
          >
            <label>Nome:</label>
            <input-component :type-input="'text'" v-model="name" />
          </row-col-component>
        </row-component>

        <button-one
          :disabled="sendRoom"
          style="width: 100%"
          :itens="buttonThree"
          @click="addNewRoom"
        />
      </form>
    </painel-component>
  </div>

  <!-- MODAL EDIT ROOM -->
  <div class="modal-add-obituary-room" v-if="modal_edit_room">
    <painel-component :title="'Editar sala'" class="container-obituary-room">
      <ErrorComponent :errors="errors" v-if="showErrorRoom" />
      <div @click="modalEditRoom" class="btn-close"></div>
      <form>
        <row-component>
          <row-col-component
            :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.value }"
          >
            <label>Código:</label>
            <input-component disabled :type-input="'text'" v-model="dataRoom.value" />
          </row-col-component>

          <row-col-component
            :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
            :class="{ formError: errors.name }"
          >
            <label>Nome:</label>
            <input-component disabled :type-input="'text'" v-model="dataRoom.name" />
          </row-col-component>
        </row-component>

        <button-one
          disabled
          style="width: 100%"
          :itens="buttonFive"
          @click="updateRoom(selectedItemId)"
        />
      </form>
    </painel-component>
  </div>

  <painel-component :title="'Editar'">
    <ErrorComponent :errors="errors" v-if="showError" />
    <div class="btn-preview">
      <button-one class="mx-3" :itens="buttonPreview" @click="routePreview(formDate.urlPreview)" />

      <button-one
        v-if="buttonCheck.create"
        class="add-button"
        :itens="buttonTwo"
        @click="routeAdd"
      />
    </div>

    <!-- TABS -->
    <div class="container-tabs mt-5">
      <div class="card tabs">
        <!-- TAB-1 -->
        <input checked id="tab-1" type="radio" class="tab tab-selector" name="tab" />
        <label for="tab-1" class="tab tab-primary">
          <i><img src="@/assets/icones/info_circle.svg" alt="" /></i>
          <span>Informações</span>
        </label>
        <!-- TAB-2 -->
        <input id="tab-2" type="radio" class="tab tab-selector" name="tab" />
        <label for="tab-2" class="tab tab-success">
          <i><img src="@/assets/icones/browser_alt.svg" alt="" /></i>
          <span>Sala</span>
        </label>
        <!-- TAB-3 -->
        <input id="tab-3" type="radio" class="tab tab-selector" name="tab" />
        <label for="tab-3" class="tab tab-success">
          <i><img src="@/assets/icones/star.svg" alt="" /></i>
          <span>Obituário</span>
        </label>

        <div class="tabsShadow"></div>
        <div class="glider"></div>

        <section class="content">
          <!-- TAB-1 -->
          <div class="item" id="content-1">
            <row-component class="my-2">
              <row-col-component
                class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
              >
                <label>API:</label>
                <div class="input-group" @click="copyLink(formDate.app_code)">
                  <input-component
                    @focus="$event.target.select()"
                    readonly
                    disabled
                    :type="'text'"
                    ref="select"
                    v-model="formDate.app_code"
                  />
                  <span role="button" class="input-group-text">
                    <i v-if="!copy"><img src="@/assets/icones/replicate.svg" /></i>
                    <i v-else><img src="@/assets/icones/check.svg" /></i>
                  </span>
                </div>
              </row-col-component>
            </row-component>
            <form>
              <row-component>
                <row-col-component
                  :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                  :class="{ formError: errors.name }"
                >
                  <label>Nome:</label>
                  <input-component :type-input="'text'" v-model="formDate.name" />
                </row-col-component>

                <row-col-component
                  :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                  :class="{ formError: errors.status }"
                >
                  <label>Status:</label>
                  <multiselect
                    v-model="formDate.status"
                    :searchable="true"
                    :options="selectActiveInactive"
                    :noResultsText="this.$store.state.var.selectNotSearch"
                  />
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                  :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                  :class="{ formError: errors.company_id }"
                >
                  <label>Empresa:</label>
                  <Multiselect
                    label="name"
                    v-model="formDate.company_id"
                    :options="selectCompanyRestrict"
                    :searchable="true"
                    :noResultsText="this.$store.state.var.selectNotSearch"
                  >
                    <template v-slot:singlelabel="{ value }">
                      <div class="multiselect-single-label">
                        <img alt="" class="character-label-icon" :src="value.icon" />
                        {{ value.name }}
                      </div>
                    </template>

                    <template v-slot:option="{ option }">
                      <img alt="" class="character-option-icon" :src="option.icon" />
                      {{ option.name }}
                    </template>
                  </Multiselect>
                </row-col-component>

                <row-col-component
                  :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                  :class="{ formError: errors.time }"
                >
                  <label>Tempo exibição em segundos:</label>
                  <input-component :type-input="'number'" v-model="formDate.time" />
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                  :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                  :class="{ formError: errors.address }"
                >
                  <label>Endereço:</label>
                  <input-component :type-input="'text'" v-model="formDate.address" />
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                  :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                  :class="{ formError: errors.title_one }"
                >
                  <label>Nome telefone:</label>
                  <input-component :type-input="'text'" v-model="formDate.title_one" />
                </row-col-component>

                <row-col-component
                  :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                  :class="{ formError: errors.phone_one }"
                >
                  <label>Telefone:</label>
                  <input-component
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    :type-input="'text'"
                    v-model="formDate.phone_one"
                  />
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                  :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                >
                  <button-one
                    :disabled="sendForm"
                    style="width: 100%"
                    :itens="buttonOne"
                    @click="submit"
                  />
                </row-col-component>

                <row-col-component
                  :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                >
                  <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
              </row-component>
            </form>
          </div>

          <!-- TAB-2 -->
          <div class="item" id="content-2">
            <button-one
              v-if="buttonCheck.room_add"
              style="margin: 1%"
              class="add-button-room"
              :itens="buttonTwo"
              @click="modalNewRoom"
            />

            <div class="container-obituary mt-5" v-for="(data, index) in roomItemData" :key="index">
              <form>
                <row-component>
                  <row-col-component
                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Code:</label>
                    <input-component disabled :type-input="'text'" v-model="data.value" />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Nome:</label>
                    <input-component disabled :type-input="'text'" v-model="data.name" />
                  </row-col-component>
                </row-component>

                <row-component class="justify-content-end">
                  <row-col-component
                    :class-row-col="'col-xxl-1 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                  >
                    <div class="btn-group float-lg-end" role="group">
                      <!-- <button-one
                        disabled
                        v-if="buttonCheck.room_edit"
                        @click="modalEditRoom(data)"
                        class="me-2"
                        :itens="buttonFour"
                      /> -->
                    </div>
                  </row-col-component>
                </row-component>
              </form>
            </div>
          </div>

          <!-- TAB-3 -->
          <div class="item" id="content-3">
            <button-one
              v-if="buttonCheck.room_add"
              style="margin: 1%"
              class="add-button-room"
              :itens="buttonTwo"
              @click="modalAddRoom"
            />

            <div
              class="container-obituary mt-5"
              v-for="(data, index) in this.formDate.obituary_room_itens"
              :key="index"
            >
              <row-col-component
                class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
              >
                <label>URL:</label>
                <div class="input-group" @click="copyLink(formDate.urlPreview)">
                  <input-component
                    @focus="$event.target.select()"
                    readonly
                    disabled
                    :type="'text'"
                    ref="select"
                    v-model="formDate.urlPreview"
                  />
                  <span role="button" class="input-group-text url-ob">
                    <i v-if="!copy"><img src="@/assets/icones/replicate.svg" /></i>
                    <i v-else><img src="@/assets/icones/check.svg" /></i>
                  </span>
                </div>
              </row-col-component>
              <form>
                <row-component>
                  <row-col-component
                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Nome:</label>
                    <input-component disabled :type-input="'text'" v-model="data.name" />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                    :class="{ formError: errors.status }"
                  >
                    <label>Local:</label>
                    <input-component disabled :type-input="'text'" v-model="data.cemetery" />
                  </row-col-component>
                </row-component>

                <row-component>
                  <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Data nascimento:</label>
                    <input-component
                      disabled
                      v-mask="['##/##/####']"
                      :type-input="'text'"
                      v-model="data.birth_date"
                    />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Data falecimento:</label>
                    <input-component
                      disabled
                      v-mask="['##/##/####']"
                      :type-input="'text'"
                      v-model="data.death_date"
                    />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Data velório:</label>
                    <input-component
                      disabled
                      v-mask="['##/##/####']"
                      :type-input="'text'"
                      v-model="data.funeral_date"
                    />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Hora velório:</label>
                    <input-component
                      disabled
                      v-mask="['##:##']"
                      :type-input="'text'"
                      v-model="data.funeral_hour"
                    />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Data sepultamento:</label>
                    <input-component
                      disabled
                      v-mask="['##/##/####']"
                      :type-input="'text'"
                      v-model="data.burial_date"
                    />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Hora sepultamento:</label>
                    <input-component
                      disabled
                      v-mask="['##:##']"
                      :type-input="'text'"
                      v-model="data.burial_hour"
                    />
                  </row-col-component>
                </row-component>

                <row-component>
                  <row-col-component
                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                  >
                    <label>N° Processo:</label>
                    <input-component disabled :type-input="'text'" v-model="data.process" />
                  </row-col-component>

                  <row-col-component
                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                    :class="{ formError: errors.status }"
                  >
                    <label>Sala:</label>
                    <input-component disabled :type-input="'text'" v-model="data.room_name" />
                  </row-col-component>
                </row-component>

                <row-component>
                  <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                  >
                    <label>Mensagem: </label>
                    <input-text-area disabled :rows="4" v-model="data.message" />
                  </row-col-component>
                </row-component>

                <row-component class="justify-content-end">
                  <row-col-component
                    :class-row-col="'col-xxl-1 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                  >
                    <div class="btn-group float-lg-end" role="group">
                      <button-one
                        class="me-2"
                        :itens="buttonPreview"
                        @click="routePreview(data.urlPreview)"
                      />

                      <button-one
                        v-if="buttonCheck.room_edit"
                        @click="modalAddRoom(1, index)"
                        class="me-2"
                        :itens="buttonFour"
                      />

                      <button
                        v-if="buttonCheck.room_delete"
                        @click="openModal(data)"
                        type="button"
                        class="btn btn-delete"
                      >
                        <img src="@/assets/icones/trash.svg" alt="" />
                      </button>
                    </div>
                  </row-col-component>
                </row-component>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ModalComponent from '@/components/modal/ModalComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import InputTextArea from '@/components/forms/InputTextArea';

import { mask } from 'vue-the-mask';
import { ACTION_MESSAGES } from '@/configs/messages';
import data from 'bootstrap/js/src/dom/data';

export default {
  created() {
    this.loadObituaryApp();
    this.$store.commit('PRELOADER', true);
    this.$store.dispatch('loadSelectCompanyRestrict');
    this.$store.dispatch('updateNamePage', 'App Obituário');
  },

  props: {
    id: { required: true }
  },

  name: 'ObituaryAppUpdate',
  components: {
    PainelComponent,
    ModalComponent,
    ErrorComponent,
    RowColComponent,
    RowComponent,
    InputComponent,
    Multiselect,
    ButtonOne,
    ButtonAdd,
    InputTextArea
  },

  directives: { mask },

  data() {
    return {
      buttonAdd: {
        route: '/obituary-app/search',
        icon: 'jump_left',
        name: 'Voltar',
        tooltip: 'Voltar'
      },

      buttonOne: {
        icon: 'floppy',
        type: 'button',
        name: 'Salvar',
        tooltip: 'Salvar'
      },

      buttonTwo: {
        icon: 'plus',
        type: 'button',
        name: '',
        tooltip: 'Adicionar'
      },

      buttonThree: {
        icon: 'plus',
        type: 'button',
        name: 'Adicionar',
        tooltip: 'Adicionar'
      },

      buttonFour: {
        icon: 'create',
        type: 'button',
        name: '',
        tooltip: ''
      },

      buttonFive: {
        icon: 'floppy',
        type: 'button',
        name: 'Salvar',
        tooltip: ''
      },

      buttonConfirm: {
        icon: '',
        type: 'button',
        name: 'Sim',
        tooltip: ''
      },

      buttonCancel: {
        icon: '',
        type: 'button',
        name: 'Não',
        tooltip: ''
      },

      buttonPreview: {
        icon: 'airplay',
        type: 'button',
        tooltip: 'Pré visualizar'
      },

      buttonCheck: {
        create: false,
        room_add: false,
        room_edit: false,
        room_delete: false
      },

      formDate: {
        company_id: '',
        name: '',
        time: '',
        type: '',
        status: '',
        code_url: '',
        address: '',
        title_one: '',
        phone_one: '',
        urlPreview: '',
        cemetery: '',
        id_obituary: '',
        obituary_room: '',
        obituary_room_itens: []
      },

      roomItemData: [],

      formDataRoom: {
        id: this.id,
        name: '',
        birth_date: '',
        death_date: '',
        funeral_hour: '',
        funeral_date: '',
        burial_hour: '',
        burial_date: '',
        cemetery: '',
        code_room: '',
        process: ''
      },

      edit: {
        id: this.id,
        idobituary: '',
        name: '',
        birth_date: '',
        death_date: '',
        funeral_hour: '',
        funeral_date: '',
        burial_hour: '',
        burial_date: '',
        cemetery: '',
        code_room: ''
      },

      delete: {
        id: this.id,
        idobituary: ''
      },

      sendRoom: false,
      btn_add_room: true,
      btn_edit_room: false,
      showErrorRoom: false,
      modal_add_room: false,
      modal_new_room: false,
      modal_edit_room: false,
      modal_delete_room: false,

      visible: false,
      message: '',

      errors: [],
      showError: false,
      sendForm: false,

      dataRoom: {
        value: '',
        name: ''
      },

      selectedItem: [],

      selectedItemId: '',

      copy: false
    };
  },

  methods: {
    routePreview(url) {
      window.open(url, '_blank', 'noreferrer');
    },

    routeAdd() {
      this.$router.push({ name: 'obituary-app-create' });
    },

    loadRoomItem() {
      this.$store
        .dispatch('loadRoomItem', this.formDate.id_obituary)
        .then(response => {
          this.roomItemData = response;
        })
        .catch(() => {
          this.$store.commit('addToast', ACTION_MESSAGES.errorDefault);
        });
    },

    loadObituaryApp() {
      this.$store
        .dispatch('loadObituaryApp', this.id)
        .then(r => {
          this.formDate = r;
          this.checkACL();
          this.loadRoomItem();
        })
        .catch(() => {
          this.$store.commit('addToast', ACTION_MESSAGES.errorDefault);
        });
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 19)
        .then(r => {
          if (r.data.indexOf('app-obituary-room-edit') !== -1) {
            this.$store.commit('PRELOADER', false);

            if (r.data.indexOf('app-obituary-room-create') !== -1) {
              this.buttonCheck.create = true;
            }

            if (r.data.indexOf('app-obituary-room-add') !== -1) {
              this.buttonCheck.room_add = true;
            }

            if (r.data.indexOf('app-obituary-room-add-edit') !== -1) {
              this.buttonCheck.room_edit = true;
            }

            if (r.data.indexOf('app-obituary-room-add-delete') !== -1) {
              this.buttonCheck.room_delete = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('updateObituaryApp', this.formDate)
          .then(() => {
            this.showError = false;
            this.sendForm = false;
            this.errors = [];
            this.$store.commit('addToast', ACTION_MESSAGES.updateDefault);
          })
          .catch(r => {
            this.showError = true;
            this.sendForm = false;
            this.errors = r.response.data.errors;
            this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault);
          });
      }
    },

    modalAddRoom(params, index) {
      this.modal_add_room = !this.modal_add_room;
      this.formDataRoom = {};
      this.formDataRoom.id = this.id;
      this.btn_add_room = true;
      this.btn_edit_room = false;
      if (params === 1) {
        this.btn_edit_room = true;
        this.btn_add_room = false;
        this.formDataRoom = this.formDate.obituary_room_itens[index];
      }
    },

    modalEditRoom(data) {
      this.modal_edit_room = !this.modal_edit_room;
      this.dataRoom = { ...data };

      const selectedRoom =
        this.formDate.obituary_room.find(item => item.name === this.dataRoom.name) || null;

      const selectedId = selectedRoom.id;
      this.selectedItemId = selectedId;
    },

    modalDeleteRoom(data) {
      this.modal_delete_room = !this.modal_delete_room;
      this.dataRoom = { ...data };

      // Encontrar o ID diretamente do obituary_room
      const selectedRoom =
        this.formDate.obituary_room.find(item => item.name === this.dataRoom.name) || null;

      const selectedId = selectedRoom.id;
      this.selectedItemId = selectedId;
    },

    submitAddRoom() {
      if (!this.sendRoom) {
        this.sendRoom = true;
        this.$store
          .dispatch('storeObituaryAppsRoom', this.formDataRoom)
          .then(() => {
            this.showErrorRoom = false;
            this.sendRoom = false;
            this.errors = [];
            this.loadObituaryApp();
            this.modal_add_room = !this.modal_add_room;
            this.$store.commit('addToast', ACTION_MESSAGES.postDefault);
          })
          .catch(r => {
            this.showErrorRoom = true;
            this.sendRoom = false;
            this.errors = r.response.data.errors;
            this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault);
          });
      }
    },

    attrEdit(id) {
      this.edit.idobituary = id;
      this.edit.name = this.formDataRoom.name;
      this.edit.birth_date = this.formDataRoom.birth_date;
      this.edit.burial_date = this.formDataRoom.burial_date;
      this.edit.death_date = this.formDataRoom.death_date;
      this.edit.burial_hour = this.formDataRoom.burial_hour;
      this.edit.funeral_date = this.formDataRoom.funeral_date;
      this.edit.funeral_hour = this.formDataRoom.funeral_hour;
      this.edit.cemetery = this.formDataRoom.cemetery;
      this.edit.code_room = this.formDataRoom.code_room;
      this.edit.process = this.formDataRoom.process;
    },

    submitEditRoom(id) {
      this.attrEdit(id);

      const formData = this.edit;

      if (!this.sendRoom) {
        this.sendRoom = true;
        this.$store
          .dispatch('updateObituaryAppsRoom', formData)
          .then(() => {
            this.showErrorRoom = false;
            this.sendRoom = false;
            this.errors = [];
            this.loadObituaryApp();
            this.modal_add_room = false;
            this.$store.commit('addToast', ACTION_MESSAGES.updateDefault);
          })
          .catch(r => {
            this.showErrorRoom = true;
            this.sendRoom = false;
            this.errors = r.response.data.errors;
            this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault);
          });
      }
    },

    destroyObituaryAppsRoom() {
      this.visible = !this.visible;
      this.$store
        .dispatch('destroyObituaryAppsRoom', this.delete)
        .then(() => {
          this.loadObituaryApp();
          this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault);
        })
        .catch(() => {
          this.loadObituaryApp();
          this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorDefault);
        });
    },

    openModal(data) {
      this.visible = !this.visible;
      this.message = `${this.me.name} , deseja realmente deletar ${data.name}`;
      this.delete.idobituary = data.id;
    },

    closeModal() {
      this.visible = !this.visible;
    },

    modalNewRoom() {
      this.modal_new_room = !this.modal_new_room;
      this.code_roow = '';
      this.name = '';
    },

    addNewRoom() {
      const formData = {
        id: this.id,
        code_room: this.code_roow,
        name: this.name
      };

      if (!this.sendRoom) {
        this.sendRoom = true;
        this.$store
          .dispatch('addNewRoom', formData)
          .then(() => {
            this.showErrorRoom = false;
            this.sendRoom = false;
            this.errors = [];
            this.loadObituaryApp();
            this.code_roow = '';
            this.name = '';
            this.modal_new_room = false;
            this.$store.commit('addToast', ACTION_MESSAGES.postDefault);
          })
          .catch(r => {
            this.showErrorRoom = true;
            this.sendRoom = false;
            this.errors = r.response.data.errors;
            this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault);
          });
      }
    },

    updateRoom(selectedItemId) {
      const payload = {
        id: selectedItemId,
        code_room: this.dataRoom.value,
        name: this.dataRoom.name
      };

      if (!this.sendRoom) {
        this.sendRoom = true;
        this.$store
          .dispatch('updateRoom', payload)
          .then(() => {
            this.showErrorRoom = false;
            this.sendRoom = false;
            this.errors = [];
            this.loadObituaryApp();
            this.modal_edit_room = false;
            this.$store.commit('addToast', ACTION_MESSAGES.updateDefault);
          })
          .catch(r => {
            this.showErrorRoom = true;
            this.sendRoom = false;
            this.errors = r.response.data.errors;
            this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault);
          });
      }
    },

    copyLink(link) {
      this.$refs.select.focus;
      navigator.clipboard.writeText(link).then(() => {
        this.copy = true;
        setTimeout(() => {
          this.copy = false;
        }, 1500);
      });
    }
  },

  computed: {
    data() {
      return data;
    },
    me() {
      return this.$store.state.auth.me;
    },

    selectCompanyRestrict() {
      return this.$store.state.var.selectCompanyRestrict;
    },

    selectActiveInactive() {
      return this.$store.state.var.selectActiveInactive;
    },

    selectObituaryList() {
      return this.$store.state.var.selectObituaryList;
    },

    modalTitle() {
      return this.btn_add_room ? 'Adicionar Obituário' : 'Editar Obituário';
    }
  }
};
</script>

<style src="@/assets/css/tabs.css"></style>

<style scoped>
.btn-preview {
  top: 0;
  right: 0;
  margin: 2%;
  position: absolute;
}

.glider,
.tabs > input[type='radio'] + label {
  width: 33.3%;
}

#tab-2:checked ~ .glider {
  left: 33.3%;
}
#tab-3:checked ~ .glider {
  left: 66.6%;
}

.add-button-room {
  top: 0;
  right: 0;
  margin: 2%;
  position: absolute;
}

.container-obituary {
  padding: 15px;
  margin: 15px 0;
  background: #e7e7e7;
}

.modal-add-obituary-room {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.5);
}

.container-obituary-room {
  width: 80%;
  height: auto;
  position: relative;
}

.container-delete-room {
  width: 30%;
  height: auto;
  position: relative;
}

.container-obituary-room .btn-close,
.container-delete-room .btn-close {
  top: 0;
  right: 0;
  margin: 1%;
  cursor: pointer;
  position: absolute;
}

.container-delete-room button {
  width: 80px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > :not(.btn-check) + .btn {
  border-radius: 0.375rem;
}

.btn-delete:not([disabled]):not(.disabled).active,
.btn-delete:not([disabled]):not(.disabled):active,
.btn-delete:not([disabled]):not(.disabled).hover,
.btn-delete:not([disabled]):not(.disabled):hover {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: transparent !important;
}

.btn-delete {
  color: white;
  float: right;
  height: 42px;
  min-width: 42px;
  line-height: 20px;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.btn-delete img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(54deg) brightness(102%) contrast(101%);
}

@media (max-width: 428px) {
  .container-obituary-room {
    width: 95%;
  }

  .container-obituary-room .btn-close {
    margin: 3%;
  }

  .add-button {
    top: 10px;
  }
}
</style>
