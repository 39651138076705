import axios from 'axios';
import { URL_BASE } from '@/configs/constants';
const RESOURCE = 'content-folder';

export default {
  loadContentsFolders(context, params) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}`, { params })
        .then(response => {
          context.commit('LOAD_CONTENT_FOLDER', response.data);
          return resolve(response.data);
        })
        .catch(() => {
          return reject();
        })
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  storeContentFolder(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}${RESOURCE}`, formData)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  loadContentFolder(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  updateContentFolder(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  destroyContentFolder(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  emptyFolder(context, folderId) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}content-file-delete-all/${folderId}`)
        .then(response => {
          return resolve(response.data);
        })
        .catch(() => {
          return reject();
        })
        .finally(() => context.commit('PRELOADER', false));
    });
  }
};
