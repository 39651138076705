<template>
    <ModalComponent @submit-logout="destroyUserLocation" @close-modal="closeModal" :visible="visible"
                    :message="message"/>

    <div class="modal-add-spot" v-if="modalAddSpot">
        <UserLocationAddSpot :id="this.idAddSpot" @close="modalAddSpot = !modalAddSpot"/>
    </div>

    <painel-component :title="'Pesquisar'">
        <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 filter-position'"
                           v-if="btnFilter">
            <button-add v-if="buttonCheck.create" class="mx-3" :itens="buttonAdd"></button-add>
            <button class="btn-filter" @click="filterWindow">
                <img src="@/assets/icones/funnel.svg" alt="">
            </button>
        </row-col-component>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                        <label>Nome do Usuário:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                                     :noResultsText="this.$store.state.var.selectNotSearch"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
                        <div class="desk-btn">
                            <button-one class="mx-1" :itens="buttonOne" @submit="submit"/>
                        </div>
                        <div class="mobi-btn">
                            <button-one class="mx-1" :itens="buttonTwo" @submit="submit"/>
                        </div>

                        <div style="display: inline;" v-if="!btnFilter">
                            <button-add class="mx-1" :itens="buttonAdd" v-if="buttonCheck.create"/>
                        </div>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <div class="__table">
            <table>
                <thead>
                <tr>
                    <th scope="col" style="width: 20%">Nome</th>
                    <th scope="col" style="width: 20%">Email</th>
                    <th scope="col" style="width: 15%">Status</th>
                    <th scope="col" style="width: 20%">Sexo</th>
                    <th scope="col" style="width: 20%"
                        v-if="buttonCheck.search || buttonCheck.edit || buttonCheck.delete">Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in user_location.data" :key="index">
                    <td data-label="Nome">
                        <div class="container-avatar-user-search">
                            <img v-if="data.photo" :src="data.photo">
                            <img v-else src="@/assets/img/avatar.png">
                            {{ data.name }}
                        </div>
                    </td>
                    <td data-label="Email">{{ data.email }}</td>
                    <td data-label="Status">{{ statusString(data.status) }}</td>
                    <td data-label="Status">{{ statusSexo(data.sexo) }}</td>
                    <td data-label="Ações" v-if="buttonCheck.search || buttonCheck.edit || buttonCheck.delete">
                        <button @click="openModalAddSpot(data.id)" class="btn-add-campaign"
                                tooltip="Adicionar Localização">
                            <img src="@/assets/icones/location-add.svg" alt="">
                        </button>
                        <button-show v-if="buttonCheck.show" :route="'user-location-show'" :id-params="data.id"/>
                        <button-edit v-if="buttonCheck.edit" :route="'user-location-edit'" :id-params="data.id"/>
                        <button-delete v-if="buttonCheck.delete" @click="openModal(data)"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <button-pagination :pagination="user_location" :offset="3" @paginate="loadUsersLocations"/>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ModalComponent from '@/components/modal/ModalComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonShow from '@/components/buttons/ButtonShow'
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import {ACTION_MESSAGES} from '@/configs/messages';
import UserLocationAddSpot from "@/views/create/UserLocationAddSpot";

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Usuário Local')
        this.loadUsersLocations(1)
        this.checkACL()

        let searchParamsStorage = this.$store.state.user_location.searchParams
        if(Object.keys(searchParamsStorage).length > 0) {
          this.formDate = searchParamsStorage
        }


        if (window.innerWidth <= 991) {
            this.filter = false
            this.btnFilter = true
        }
    },

    name: 'UserLocationSearch',

    components: {
        PainelComponent,
        ModalComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonOne,
        ButtonAdd,
        ButtonEdit,
        ButtonShow,
        ButtonDelete,
        ButtonPagination,
        UserLocationAddSpot
    },

    data() {
        return {
            switches: false,
            buttonAdd: {
                route: '/user-location/create',
                icon: 'plus',
                tooltip: 'Adicionar'
            },

            buttonOne: {
                icon: '',
                tooltip: 'Buscar',
                name: 'Buscar',
                type: 'button'
            },

            buttonTwo: {
                icon: 'search',
                tooltip: '',
                name: '',
                type: 'button'
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false,
                show: false,
            },

            formDate: {
                name: '',
                status: ''
            },

            filter: true,
            btnFilter: false,

            modalAddSpot: false,
            idAddSpot: '',

            idModal: '',
            message: '',
            visible: false,
            searchSubmit: false,
            searchParams: ''
        }
    },

    methods: {
        filterWindow() {
            this.filter = !this.filter
        },

        submit() {
            this.loadUsersLocations(1)
            this.searchSubmit = true;
        },

        loadUsersLocations(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadUsersLocations', {...this.params, page})
                    .then((r) => {
                        if(this.searchParams ==1)
                        this.$store.commit('UPDATE_SEARCH_PARAMS_USERS_LOCATIONS', this.params)

                        this.formDate = this.params;
                        this.searchParams = 1


                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data == '') {
                                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 18)
                .then((r) => {
                    if (r.data.indexOf('user-location-search') !== -1) {

                        if (r.data.indexOf('user-location-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('user-location-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('user-location-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('user-location-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }

                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        openModalAddSpot(id) {
            this.idAddSpot = id
            this.modalAddSpot = !this.modalAddSpot
        },

        openModal(data) {
            if (data.id == this.me.id) {
                this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorMe);
                return false
            }
            this.visible = !this.visible
            this.message = `${this.me.name} , deseja realmente deletar ${data.name}`
            this.idModal = data.id
        },

        closeModal() {
            this.visible = !this.visible
        },

        destroyUserLocation() {
            this.visible = !this.visible
            this.$store.dispatch('destroyUserLocation', this.idModal)
                .then(() => {
                    this.loadUsersLocations(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault)
                })
                .catch(() => {
                    this.loadUsersLocations(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorDefault)
                })
        },

        statusString(value) {
            if (value == 1)
                return "Ativo"
            if (value == 2) {
                return "Inativo"
            }
        },

        statusSexo(value) {
            if (value == 1)
                return 'Feminino'
            if (value == 2)
                return 'Masculino'
        }
    },

    computed: {
        user_location() {
            return this.$store.state.user_location.items
        },

        me() {
            return this.$store.state.auth.me
        },

        params() {
            let searchParamsStorage = this.$store.state.user_location.searchParams
            if(Object.keys(searchParamsStorage).length > 0)
                return this.$store.state.user_location.searchParams

            return {
                page: this.user_location.current_page,
                name: this.formDate.name,
                status: this.formDate.status
            }
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }

}
</script>
<style src="@/assets/css/filterSearch.css"></style>

<style scoped>
.btn-add-campaign {
    border: 0;
    outline: none;
    position: relative;
    margin-right: 15px;
    background-color: transparent;
}

.btn-add-campaign:not([disabled]):hover,
.btn-add-campaign:not([disabled]):active {
    background: none !important;
}

.modal-add-spot {
    top: 0;
    left: 0;
    z-index: 15;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    overflow: hidden;
    place-items: center;
    background-color: rgb(0, 0, 0, 0.7);
}

.modal-add-spot > div {
    width: 80%;
    height: 85%;
}


.container-avatar-user-search {
    display: flex;
    align-items: center;
    height: auto;
}

.container-avatar-user-search > img {
    border-radius: 50%;
    margin-right: 15px;
    height: 3rem;
    width: 3rem;
}

.desk-btn {
    display: none;
}

.mobi-btn {
    display: inline;
}

@media (max-width: 991px) {
    .modal-add-spot > div {
        width: 95%;
        height: 80%;
    }

    .container-avatar-user-search {
        height: 100%;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
    }

    .container-avatar-user-search > img {
        margin-right: 0;
        margin-left: 10px;
        height: 2rem;
        width: 2rem;
    }

    .desk-btn {
        display: inline;
    }

    .mobi-btn {
        display: none;
    }

}

@media (max-width: 425px) {
    .modal-add-spot > div {
        width: 95%;
        height: 80%;
        overflow-y: scroll;
    }
}

@media (max-width: 375px) {
    .modal-add-spot > div {
        width: 95%;
        height: 95%;
    }
}
</style>
