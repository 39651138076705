<template>
    <painel-component :title="'Pesquisar'">

        <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 filter-position'"
                           v-if="btnFilter">
            <button-add v-if="buttonCheck.create" class="mx-3" :itens="buttonAdd"></button-add>
            <button class="btn-filter" @click="filterWindow">
                <img src="@/assets/icones/funnel.svg" alt="">
            </button>
        </row-col-component>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <Multiselect :noResultsText="this.$store.state.var.selectNotSearch" :searchable="true"
                                     :options="selectActiveInactive" v-model="formDate.status"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                        <label>Empresa:</label>
                        <Multiselect label="name" v-model="formDate.company_id" :options="selectCompanyRestrict"
                                     :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon"> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon"> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
                        <div class="desk-btn"><button-one class="mx-1" :itens="buttonOne" @submit="submit"/></div>
                        <div class="mobi-btn"><button-one class="mx-1" :itens="buttonTwo" @submit="submit"/></div>
                        
                        <div v-if="!btnFilter" style="display: inline;">
                            <button-add class="mx-1" :itens="buttonAdd" v-if="buttonCheck.create"/>
                        </div>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <div class="__table">
            <table>
                <thead>
                <tr>
                    <th scope="col" style="width: 30%">Nome</th>
                    <th scope="col" style="width: 30%">Criador</th>
                    <th scope="col" style="width: 30%">Status</th>
                    <th scope="col" style="width: 10%" v-if="buttonCheck.show || buttonCheck.edit">Ações</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in spot.data" :key="index">
                    <td data-label="Nome">{{ data.name }}</td>
                    <td data-label="Criador">{{ data.creator_user_name }}</td>
                    <td data-label="Status">{{ statusString(data.status) }}</td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.edit">
                        <button-edit v-if="buttonCheck.edit" :route="'spot-edit'" :id-params="data.id"/>
                        <button-show v-if="buttonCheck.show" :route="'spot-show'" :id-params="data.id"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <button-pagination :pagination="spot" :offset="3" @paginate="loadSpots"/>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonShow from '@/components/buttons/ButtonShow';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonPagination from '@/components/buttons/ButtonPagination'

import {ACTION_MESSAGES} from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Localização')
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.$store.commit('PRELOADER', true)
        this.loadSpots(1)
        this.checkACL()

        let searchParamsStorage = this.$store.state.spot.searchParams
        if(Object.keys(searchParamsStorage).length > 0) {
          this.formDate = searchParamsStorage
        }


        if (window.innerWidth <= 991) {
            this.filter = false
            this.btnFilter = true
        }
    },

    name: 'SpotSearch',
    components: {
        PainelComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
        ButtonOne,
        ButtonShow,
        ButtonEdit,
        ButtonPagination
    },

    data() {
        return {
            buttonAdd: {
                icon: 'plus',
                route: '/spot/create',
                tooltip: 'Adicionar'
            },

            buttonOne: {
                icon: '',
                tooltip: 'Buscar',
                name: 'Buscar',
                type: 'button'
            },

            buttonTwo: {
                icon: 'search',
                tooltip: '',
                name: '',
                type: 'button'
            },
            
            buttonCheck: {
                create: false,
                edit: false,
                delete: false,
                show: false,
            },

            formDate: {
                name: '',
                status: '',
                company_id: ''
            },

            filter: true,
            btnFilter: false,

            searchSubmit: false,

            searchParams: ''

        }
    },

    methods: {
        filterWindow() {
            this.filter = !this.filter
        },

        submit() {
            this.loadSpots(1)
            this.searchSubmit = true;
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 5)
                .then((r) => {
                    if (r.data.indexOf('spot-search') !== -1) {
                        this.$store.commit('PRELOADER', false)

                        if (r.data.indexOf('spot-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('spot-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('spot-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('spot-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadSpots(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadSpots', {...this.params, page})
                    .then((r) => {
                        this.showLoder = false

                        if(this.searchParams ==1)
                        this.$store.commit('UPDATE_SEARCH_PARAMS_SPOTS', this.params)

                        this.formDate = this.params;
                        this.searchParams = 1


                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data == '') {
                                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false
                });
            }
        },

        statusString(value) {
            if (value == 1)
                return "Ativo"
            if (value == 2) {
                return "Inativo"
            }
        }
    },

    computed: {
        spot() {
            return this.$store.state.spot.items
        },

        params() {
            let searchParamsStorage = this.$store.state.spot.searchParams
            if(Object.keys(searchParamsStorage).length > 0)
                return this.$store.state.spot.searchParams


            return {
                page: this.spot.current_page,
                name: this.formDate.name,
                status: this.formDate.status,
                company_id: this.formDate.company_id
            }
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        },

        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },
    }
}

</script>

<style src="@/assets/css/filterSearch.css"></style>

<style scoped>

.desk-btn {
    display: none;
}

.mobi-btn {
    display: inline;
}

@media (max-width: 991px) {

    .desk-btn {
        display: inline;
    }

    .mobi-btn {
        display: none;
    }

}

</style>