<template>
  <ModalComponent
    @submit-logout="destroyCampaign"
    @close-modal="closeModal"
    :visible="visible"
    :message="message"
  />
  <painel-component :title="'Pesquisar'">
    <div class="campaign-add-player" v-if="addPlayer">
      <CampaignAddPlayer
        class="campaign-add-player-container"
        :id="idAddPlayer"
        @close-campaign-add-player="addPlayer = !addPlayer"
      />
    </div>

    <!-- MODAL AGENDAMENTO -->
    <div class="modal-window" v-if="opeModalSchedule">
      <div class="container-card camp-schedule">
        <div role="button" class="btn-close" data-v-3c7c0b1c="" @click="modalSchedule"></div>
        <h1>Agendamento</h1>
        <form>
          <!-- Seção de seleção de tipo de agendamento -->
          <div class="mt-4">
            <label>Selecione o tipo de agendamento:</label>
            <row-component>
              <!-- Input Semanal -->
              <row-col-component
                :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12'"
              >
                <input
                  id="semanal"
                  class="check-box-schedule"
                  type="radio"
                  value="1"
                  v-model="type"
                />
                <label for="semanal">Semanal</label>
              </row-col-component>

              <!-- Input Período -->
              <row-col-component
                :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12'"
              >
                <input
                  id="periodo"
                  class="check-box-schedule"
                  type="radio"
                  value="2"
                  v-model="type"
                />
                <label for="periodo">Período</label>
              </row-col-component>

              <!-- Input Remoção -->
              <row-col-component
                :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12'"
              >
                <input
                  id="remocao"
                  class="check-box-schedule"
                  type="radio"
                  value="3"
                  v-model="type"
                />
                <label for="remocao">Remoção</label>
              </row-col-component>
            </row-component>
          </div>

          <!-- Formulário para tipo Semanal -->
          <div v-if="type === '1'">
            <row-col-component
              :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
            >
              <label>Dias da Semana:</label>
              <div
                class="form-check form-check-inline"
                v-for="(dia, index) in days_week"
                :key="dia.value"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="dia.value"
                  v-model="selectedDays"
                  :id="'checkbox-' + index"
                />
                <label class="form-check-label mx-1" :for="'checkbox-' + index">
                  {{ dia.label }}
                </label>
              </div>
            </row-col-component>
          </div>

          <!-- Formulário para tipo Período -->
          <div v-if="type === '2'">
            <row-component>
              <row-col-component
                :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
              >
                <label>Data inicial:</label>
                <input-component :type-input="'date'" v-model="date_start" />
              </row-col-component>

              <row-col-component
                :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
              >
                <label>Data final:</label>
                <input-component :type-input="'date'" v-model="date_end" />
              </row-col-component>

              <row-col-component
                :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
              >
                <label>Excluir campanha:</label>
                <Multiselect :options="selectNoYes" v-model="remove_campaign" />
              </row-col-component>
            </row-component>
          </div>

          <!-- Formulário para tipo Remoção -->
          <div v-if="type === '3'">
            <row-component>
              <row-col-component
                :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
              >
                <label>Data:</label>
                <input-component :type-input="'date'" v-model="date_remove" />
              </row-col-component>
            </row-component>
          </div>

          <!-- Botões de ação -->
          <button-one
            v-if="scheduling === 1"
            :itens="buttonThree"
            @click="addScheduling(campaingId)"
          />
          <button-one
            v-if="scheduling === 2"
            :itens="buttonSave"
            @click="updateSchedulingCampaing(schedulingId)"
          />
          <button-remove
            v-if="scheduling === 2"
            :itens="buttonRemove"
            class="mx-2"
            @click="deleteSchedulingCampaing(schedulingId)"
          />
        </form>
      </div>
    </div>

    <div class="modal-window" v-if="modal_share || share_edit">
      <CampaignShareCreate
        class="camp-share"
        :id="id_campaign"
        v-if="modal_share"
        @close="closeShare"
      />
      <CampaignShareUpdate
        class="camp-share"
        :id="id_campaign"
        :id_share="id_share"
        v-if="share_edit"
        @close="closeShare"
      />
    </div>

    <row-col-component
      :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 filter-position'"
      v-if="btnFilter"
    >
      <button-add v-if="buttonCheck.create" class="mx-3" :itens="buttonAdd" />
      <button class="btn-filter" @click="filterWindow">
        <img src="@/assets/icones/funnel.svg" alt="" />
      </button>
    </row-col-component>

    <form class="mb-4 mt-5">
      <transition name="fade-in-top">
        <row-component v-if="filter">
          <row-col-component
            :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
          >
            <label>Nome:</label>
            <input-component :type-input="'text'" v-model="formDate.name" />
          </row-col-component>

          <row-col-component
            :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
          >
            <label>Empresa:</label>
            <Multiselect
              v-model="formDate.company_id"
              :searchable="true"
              label="name"
              :options="selectCompanyRestrict"
            >
              <template v-slot:singlelabel="{ value }">
                <div class="multiselect-single-label">
                  <img class="character-label-icon" :src="value.icon" alt="" />
                  {{ value.name }}
                </div>
              </template>

              <template v-slot:option="{ option }">
                <img class="character-option-icon" :src="option.icon" alt="" />
                {{ option.name }}
              </template>
            </Multiselect>
          </row-col-component>

          <row-col-component
            :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'"
          >
            <div class="desk-btn">
              <button-one class="mx-1" :itens="buttonOne" @submit="submit" />
            </div>
            <div class="mobi-btn">
              <button-one class="mx-1" :itens="buttonTwo" @submit="submit" />
            </div>

            <div v-if="!btnFilter" style="display: inline">
              <button-add v-if="buttonCheck.create" class="mx-1" :itens="buttonAdd" />
            </div>
          </row-col-component>
        </row-component>
      </transition>
    </form>

    <div class="__table">
      <table>
        <thead>
          <tr>
            <th scope="col" style="width: 40%">Nome</th>
            <th scope="col" style="width: 40%">Empresa</th>
            <th
              scope="col"
              style="width: 20%"
              v-if="buttonCheck.edit || buttonCheck.delete || buttonCheck.campaing_add_player"
            >
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in campaign.data" :key="index">
            <td data-label="Nome">{{ data.name }}</td>
            <td data-label="Empresa">{{ data.company_name }}</td>
            <td
              data-label="Ações"
              v-if="
                buttonCheck.edit ||
                buttonCheck.delete ||
                buttonCheck.campaing_add_player ||
                buttonCheck.campaing_share
              "
            >
              <button :data-player="data.total_player_add" class="btn-add-campaign">
                <img
                  v-if="buttonCheck.campaing_add_player"
                  @click="addPLayerModal(data.id)"
                  src="@/assets/icones/play_button.svg"
                />
              </button>

              <button class="btn-share" @click="modalShare(data)" v-if="buttonCheck.campaing_share">
                <img
                  :class="data.share ? 'active' : ''"
                  src="@/assets/icones/location.svg"
                  alt=""
                />
              </button>

              <!-- <button-calendar @click="abrirModal" />: -->
              <button
                class="btn-schedule"
                @click="modalSchedule(data)"
                v-if="buttonCheck.campaing_schedule"
                tooltip="Agendamento"
              >
                <img
                  :src="require('@/assets/icones/calendar_month.svg')"
                  :class="{ 'orange-icon': data.scheduling === 2 }"
                />
              </button>

              <button-edit v-if="buttonCheck.edit" :route="'campaign-edit'" :id-params="data.id" />
              <button-delete v-if="buttonCheck.delete" @click="openModal(data)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <button-pagination :pagination="campaign" :offset="3" @paginate="loadCampaigns" />
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ModalComponent from '@/components/modal/ModalComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import { ACTION_MESSAGES } from '@/configs/messages';
import CampaignAddPlayer from '@/views/create/CampaignAddPlayer';
import CampaignShareCreate from '@/views/create/CampaignShareCreate.vue';
import CampaignShareUpdate from '@/views/update/CampaignShareUpdate.vue';
import ButtonRemove from '@/components/buttons/ButtonRemove';

export default {
  created() {
    this.checkACL();
    this.loadCampaigns(1);
    this.$store.dispatch('loadSelectCompanyRestrict');
    this.$store.dispatch('updateNamePage', 'Campanha');

    let searchParamsStorage = this.$store.state.campaign.searchParams;
    if (Object.keys(searchParamsStorage).length > 0) {
      this.formDate = searchParamsStorage;
    }

    if (window.innerWidth <= 991) {
      this.filter = false;
      this.btnFilter = true;
    }
  },

  name: 'CampaingSearch',

  components: {
    CampaignShareUpdate,
    CampaignShareCreate,
    PainelComponent,
    ModalComponent,
    RowColComponent,
    RowComponent,
    InputComponent,
    Multiselect,
    ButtonAdd,
    ButtonOne,
    ButtonEdit,
    ButtonDelete,
    ButtonPagination,
    CampaignAddPlayer,
    ButtonRemove
  },

  data() {
    const today = new Date();
    const dateStart = today.toISOString().split('T')[0];
    const dateEnd = new Date(today);
    dateEnd.setDate(today.getDate() + 30);
    const formattedDateEnd = dateEnd.toISOString().split('T')[0];

    return {
      switches: false,
      buttonAdd: {
        route: '/campaign/create',
        icon: 'plus',
        tooltip: 'Adicionar'
      },

      buttonOne: {
        icon: '',
        tooltip: 'Buscar',
        name: 'Buscar',
        type: 'button'
      },

      buttonTwo: {
        icon: 'search',
        tooltip: '',
        name: '',
        type: 'button'
      },

      buttonSave: {
        icon: 'floppy',
        type: 'button',
        name: 'Salvar',
        tooltip: 'Salvar'
      },

      buttonThree: {
        icon: 'plus',
        type: 'button',
        name: 'Adicionar',
        tooltip: 'Adicionar'
      },

      buttonCheck: {
        create: false,
        edit: false,
        delete: false,
        campaing_add_player: false,
        campaing_share: false,
        campaing_schedule: false
      },

      buttonRemove: {
        icon: 'trash',
        type: 'button',
        name: 'Remover',
        tooltip: 'Remover'
      },

      formDate: {
        name: '',
        company_id: ''
      },

      filter: true,
      btnFilter: false,

      addPlayer: false,
      idAddPlayer: '',

      id_campaign: '',
      id_share: '',
      share_edit: false,
      modal_share: false,

      idModal: '',
      message: '',
      visible: false,
      searchSubmit: false,
      opeModalSchedule: false,
      type: 1,
      selectedDays: [],
      date_start: dateStart,
      date_end: formattedDateEnd,
      schedulingId: null
    };
  },

  watch: {
    opeModalSchedule(newVal) {
      if (newVal) {
        this.loadCampaingScheduling();
      } else {
        this.resetForm();
      }
    },
    type(newVal) {
      if (newVal) {
        this.selectedType = newVal;
      }
    }
  },

  methods: {
    filterWindow() {
      this.filter = !this.filter;
    },

    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },

    submit() {
      this.loadCampaigns(1);
      this.searchSubmit = true;
    },

    addPLayerModal(id) {
      this.idAddPlayer = id;
      this.addPlayer = !this.addPlayer;
    },

    modalShare(data) {
      this.id_campaign = data.id;
      this.id_share = data.shareID;

      if (data.share === true) this.share_edit = true;
      if (data.share === false) this.modal_share = true;
    },

    closeShare(n) {
      this.modal_share = false;
      this.share_edit = false;

      if (n === 1) {
        this.loadCampaigns();
        this.modal_share = false;
        this.share_edit = false;
      }
    },

    loadCampaigns(page) {
      if (!this.searchSubmit) {
        this.$store
          .dispatch('loadCampaigns', { ...this.params, page })
          .then(r => {
            if (this.searchParams == 1)
              this.$store.commit('UPDATE_SEARCH_PARAMS_CAMPAIGNS', this.params);
            this.formDate = this.params;
            this.searchParams = 1;

            if (this.searchSubmit) {
              this.searchSubmit = false;
              if (r.data == '') {
                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert);
              }
            }
          })
          .catch(() => {
            this.searchSubmit = false;
          });
      }
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 7)
        .then(r => {
          if (r.data.indexOf('campaing-search') !== -1) {
            if (r.data.indexOf('campaing-edit') !== -1) {
              this.buttonCheck.edit = true;
            }

            if (r.data.indexOf('campaing-delete') !== -1) {
              this.buttonCheck.delete = true;
            }

            if (r.data.indexOf('campaing-create') !== -1) {
              this.buttonCheck.create = true;
            }

            if (r.data.indexOf('campaing-add-player') !== -1) {
              this.buttonCheck.campaing_add_player = true;
            }

            if (r.data.indexOf('campaing-share') !== -1) {
              this.buttonCheck.campaing_share = true;
            }

            if (r.data.indexOf('campaing-share') !== -1) {
              this.buttonCheck.campaing_schedule = true;
            }
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    },

    openModal(data) {
      this.visible = !this.visible;
      this.message = `${this.me.name} , deseja realmente deletar ${data.name}`;
      this.idModal = data.id;
    },

    closeModal() {
      this.visible = !this.visible;
    },

    destroyCampaign() {
      this.visible = !this.visible;
      this.$store
        .dispatch('destroyCampaign', this.idModal)
        .then(() => {
          this.loadCampaigns(this.campaign.current_page);
          this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault);
        })
        .catch(() => {
          this.loadCampaigns(this.campaign.current_page);
          this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorDefault);
        });
    },

    modalSchedule(data) {
      this.opeModalSchedule = !this.opeModalSchedule;
      this.campaingId = data.id;
      this.schedulingId = data.scheduling.id;
      this.scheduling = data.scheduling;
      this.type = '1';

      if (this.opeModalSchedule) {
        this.loadCampaingScheduling();
      } else {
        this.resetForm();
      }
    },

    resetForm() {
      this.type = null;
      this.selectedDays = [];
      this.remove_campaign = null;
      this.date_remove = '';
    },

    addScheduling(campaingId) {
      const formattedDateStart = this.formatDate(this.date_start);
      const formattedDateEnd = this.formatDate(this.date_end);
      const formattedDateRemove = this.formatDate(this.date_remove);

      const selectedDays = this.selectedDays.filter(day => day !== null && day !== undefined);

      const requestData = {
        id: campaingId,
        type: this.type,
        days_week: selectedDays,
        date_start: formattedDateStart,
        date_end: formattedDateEnd,
        remove_campaign: this.remove_campaign,
        date_remove: formattedDateRemove
      };

      this.$store
        .dispatch('addScheduling', requestData)
        .then(() => {
          this.$store.commit('addToast', ACTION_MESSAGES.updateDefault);
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const errorMessage = err.response.data.errors[0][0];

            this.$store.commit('addToast', { message: errorMessage, type: 'error' });
          } else {
            this.$store.commit('addToast', { message: 'Erro desconhecido', type: 'error' });
          }
        })
        .finally(() => {
          this.loadCampaigns(this.campaign.current_page);
          this.opeModalSchedule = false;
          this.selectedDays = [];
        });
    },

    loadCampaingScheduling() {
      this.$store
        .dispatch('loadCampaingScheduling', this.campaingId)
        .then(schedulingData => {
          if (schedulingData) {
            this.type = schedulingData.type;

            if (this.type === '1') {
              this.selectedDays = schedulingData.days_week || [];
            } else if (this.type === '2') {
              if (schedulingData.date_start) {
                this.date_start = schedulingData.date_start.split('/').reverse().join('-');
              } else {
                this.date_start = '';
              }

              if (schedulingData.date_end) {
                this.date_end = schedulingData.date_end.split('/').reverse().join('-');
              } else {
                this.date_end = '';
              }

              this.remove_campaign = schedulingData.remove_campaign || null;
            } else if (this.type === '3') {
              if (schedulingData.date_remove) {
                this.date_remove = schedulingData.date_remove.split('/').reverse().join('-');
              } else {
                this.date_remove = '';
              }
            }

            this.schedulingId = schedulingData.id;
          }
        })
        .catch(error => {
          console.error('Erro ao carregar os dados do agendamento:', error);
        });
    },

    updateSchedulingCampaing(schedulingId) {
      const formattedDateStart = this.formatDate(this.date_start);
      const formattedDateEnd = this.formatDate(this.date_end);
      const formattedDateRemove = this.formatDate(this.date_remove);

      const selectedDays = this.selectedDays.filter(day => day !== null && day !== undefined);

      const requestData = {
        id: schedulingId,
        type: this.type,
        days_week: selectedDays,
        date_start: formattedDateStart,
        date_end: formattedDateEnd,
        remove_campaign: this.remove_campaign,
        date_remove: formattedDateRemove
      };

      this.$store
        .dispatch('updateSchedulingCampaing', requestData)
        .then(() => {
          this.$store.commit('addToast', ACTION_MESSAGES.updateDefault);
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const errorMessage = err.response.data.errors[0][0];
            this.$store.commit('addToast', { message: errorMessage, type: 'error' });
          } else {
            this.$store.commit('addToast', { message: 'Erro desconhecido', type: 'error' });
          }
        })
        .finally(() => {
          this.loadCampaigns(this.campaign.current_page);
          this.opeModalSchedule = false;
          this.selectedDays = [];
        });
    },

    deleteSchedulingCampaing(schedulingId) {
      this.$store
        .dispatch('deleteSchedulingCampaing', schedulingId)
        .then(() => {
          this.$store.commit('addToast', ACTION_MESSAGES.delete);
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const errorMessage = err.response.data.errors[0][0];
            this.$store.commit('addToast', { message: errorMessage, type: 'error' });
          } else {
            this.$store.commit('addToast', { message: 'Erro desconhecido', type: 'error' });
          }
        })
        .finally(() => {
          this.loadCampaigns(this.campaign.current_page);
          this.opeModalSchedule = false;
          this.selectedDays = [];
          this.resetForm();
        });
    }
  },

  computed: {
    me() {
      return this.$store.state.auth.me;
    },

    campaign() {
      return this.$store.state.campaign.items;
    },

    params() {
      let searchParamsStorage = this.$store.state.campaign.searchParams;
      if (Object.keys(searchParamsStorage).length > 0)
        return this.$store.state.campaign.searchParams;

      return {
        page: this.campaign.current_page,
        name: this.formDate.name,
        company_id: this.formDate.company_id
      };
    },

    selectCompanyRestrict() {
      return this.$store.state.var.selectCompanyRestrict;
    },

    selectNoYes() {
      return this.$store.state.var.selectNoYes;
    },

    days_week() {
      return this.$store.state.var.days_week;
    }
  }
};
</script>

<style src="@/assets/css/filterSearch.css"></style>

<style scoped>
.btn-add-campaign,
.btn-share {
  border: 0;
  outline: none;
  position: relative;
  margin-right: 15px;
  background-color: transparent;
}

.btn-share img.active {
  filter: invert(56%) sepia(66%) saturate(874%) hue-rotate(77deg) brightness(91%) contrast(82%);
}

.btn-add-campaign:not([disabled]):hover,
.btn-share:not([disabled]):hover,
.btn-add-campaign:not([disabled]):active,
.btn-share:not([disabled]):active {
  background: none !important;
}

.btn-add-campaign::before {
  content: attr(data-player);
  z-index: 1;
  width: 25px;
  height: 25px;
  top: -35%;
  left: -110%;
  color: black;
  font-weight: bold;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  background-color: #d3d3d3;
}

.campaign-add-player {
  top: 0;
  left: 0;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: fixed;
  overflow: hidden;
  place-items: center;
  background-color: rgb(0, 0, 0, 0.7);
}

.modal-window {
  top: 0;
  left: 0;
  z-index: 11;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: fixed;
  place-items: center;
  background-color: rgb(0, 0, 0, 0.7);
}

.modal-window > .camp-share,
.camp-schedule {
  width: 70%;
}

.campaign-add-player > .campaign-add-player-container {
  width: 80%;
  height: 85%;
  overflow-y: scroll;
}

.desk-btn {
  display: none;
}

.mobi-btn {
  display: inline;
}

.btn-schedule {
  border: 0;
  outline: none;
  position: relative;
  margin-right: 15px;
  background-color: transparent;
}

.btn-schedule:hover {
  background-color: transparent !important;
}

/*  */

@media (max-width: 991px) {
  .campaign-add-player > .campaign-add-player-container {
    width: 97%;
    height: auto;
    overflow-y: scroll;
  }

  .modal-window > .camp-share {
    width: 97%;
    height: auto;
    overflow-y: scroll;
  }

  .desk-btn {
    display: inline;
  }

  .mobi-btn {
    display: none;
  }
}

.orange-icon {
  filter: brightness(0) saturate(100%) invert(58%) sepia(52%) saturate(2034%) hue-rotate(340deg)
    brightness(97%) contrast(101%);
}

button:hover .orange-icon {
  filter: brightness(0) saturate(100%) invert(49%) sepia(57%) saturate(5156%) hue-rotate(358deg)
    brightness(50%) contrast(108%);
}

@media (max-width: 425px) {
  .campaign-add-player > .campaign-add-player-container {
    width: 95%;
    height: auto;
    overflow-y: scroll;
  }
}

@media (max-width: 375px) {
  .campaign-add-player > .campaign-add-player-container {
    width: 95%;
    height: auto;
    overflow-y: scroll;
  }
}
</style>
