<template>
  <button class="btn ButtonRemove" :tooltip="itens.tooltip" :type="itens.type" @click="submit">
    <i class="icon"><img v-if="itens.icon" :src="require(`@/assets/icones/${itens.icon}.svg`)" /></i>
    {{ itens.name }}
  </button>
</template>

<script>
export default {
  name: 'ButtonOne',

  props: {
    itens: {
      type: Object
    }
  },

  data() {
    return {
      buttonLoader: false
    };
  },

  methods: {
    submit() {
      if (this.loderButton) {
        this.buttonLoader == true;
      }
      this.$emit('submit');
    }
  }
};
</script>

<style scoped>
.ButtonRemove {
  line-height: 20px;
  /* min-width: 115px; */
  min-width: 42px;
  height: 42px;
  background: #4d4946 !important;
  border-radius: 5px;
  border: 0;
  color: #fff !important;
  cursor: pointer;
}

.ButtonRemove:active,
.ButtonRemove:focus,
.ButtonRemove:hover,
.ButtonRemove:focus-visible {
  background-color: #4d4946 !important;
  color: white !important;
  border-color: #4d4946;
  box-shadow: none !important;
}

.ButtonRemove:not([disabled]):hover,
.ButtonRemove:not([disabled]):active {
  background-color: #5e5a56 !important;
  color: white;
}

i.icon img {
  vertical-align: bottom;
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(338deg) brightness(110%) contrast(101%);
}

.preloderSend {
  padding-right: 40px;
}

.preloderSend:after {
  content: '';
  position: absolute;
  animation: load 3s infinite;
  margin-left: 4px;
}

@keyframes load {
  0% {
    content: '';
  }

  33% {
    content: '.';
  }

  66% {
    content: '..';
  }

  99% {
    content: '...';
  }
}
</style>
