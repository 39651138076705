import axios from 'axios';
import { URL_BASE } from '@/configs/constants';

const RESOURCE = 'campaing';

export default {
  loadCampaigns(context, params) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}`, { params })
        .then(response => {
          context.commit('LOAD_CAMPAIGNS', response.data);
          return resolve(response.data);
        })
        .catch(() => {
          return reject();
        })
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  storeCampaign(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}${RESOURCE}`, formData)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  loadCampaign(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  updateCampaign(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  destroyCampaign(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}${RESOURCE}/${id}`)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  loadInfosAppFile(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}campaing-info-app-file/${formData.idCampaing}/${formData.idAppFile}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  campaingSynchronize(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}campaing-synchronize`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  doubleCampaignAppFile(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}campaing-double-app-file/${formData.id}/${formData.idAppFile}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  destroyDeleteAppFile(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}campaing-delete-app-file/${formData.id}/${formData.idAppFile}`)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  loadCampaignShare(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}campaing-share-get/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  storeCampaignShare(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}campaing-share`, formData)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  updateCampaignShare(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}campaing-share-update/${formData.id}`, formData)
        .then(r => {
          resolve(r);
        })
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  // ADD SCHEDULING
  addScheduling(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL_BASE}campaing-add-scheduling`, formData)
        .then(r => resolve(r))
        .catch(errors => reject(errors))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  loadCampaingScheduling(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}campaing-get-scheduling/${id}`)
        .then(response => {
          context.commit('PRELOADER', response.data);
          resolve(response.data);
        })
        .catch(e => reject(e))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  updateSchedulingCampaing(context, formData) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL_BASE}campaing-update-scheduling/${formData.id}`, formData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          context.commit('PRELOADER', false);
        });
    });
  },

  deleteSchedulingCampaing(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL_BASE}campaing-delete-scheduling/${id}`)
        .then(response => resolve(response.data))
        .catch(errors => reject(errors))
        .finally(() => {
          context.commit('PRELOADER', false);
        });
    });
  }
};
