<template>
    <ModalComponent @submit-logout="destroyUser" @close-modal="closeModal" :visible="visible" :message="message"/>
    <painel-component :title="'Pesquisar'">
        <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 filter-position'"
                           v-if="btnFilter">
            <button-add v-if="buttonCheck.create" class="mx-3" :itens="buttonAdd"></button-add>
            <button class="btn-filter" @click="filterWindow">
                <img src="@/assets/icones/funnel.svg" alt="">
            </button>
        </row-col-component>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                        <label>Nome do Usuário:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                                     :noResultsText="this.$store.state.var.selectNotSearch"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
                        <div class="desk-btn">
                            <button-one class="mx-1" :itens="buttonOne" @submit="submit"/>
                        </div>
                        <div class="mobi-btn">
                            <button-one class="mx-1" :itens="buttonTwo" @submit="submit"/>
                        </div>

                        <div style="display: inline;" v-if="!btnFilter">
                            <button-add class="mx-1" :itens="buttonAdd" v-if="buttonCheck.create"/>
                        </div>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <div class="alert alert-secondary icon-message" role="alert">
                    <img src="@/assets/icones/users.svg">
                    Total de {{ pluralSingOne(user.totalUser) }}: <b>{{ user.totalUser }}</b>
                </div>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <div class="alert alert-secondary icon-message" role="alert">
                    <img src="@/assets/icones/users.svg">
                    Limite de {{ pluralSingTwo(user.totalUserHired) }}: <b>{{user.totalUserHired}}</b>
                </div>
            </row-col-component>
        </row-component>
       <div class="__table">
            <table>
                <thead>
                <tr>
                    <th scope="col" style="width: 20%">Nome</th>
                    <th scope="col" style="width: 20%">Email</th>
                    <th scope="col" style="width: 20%">Status</th>
                    <th scope="col" style="width: 15%"
                        v-if="buttonCheck.search || buttonCheck.edit || buttonCheck.delete">Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in object" :key="index">
                    <td data-label="Nome">
                        <div class="container-avatar-user-search">
                            <img v-if="data.photo" :src="data.photo">
                            <img v-else src="@/assets/img/avatar.png">
                            {{ data.name }}
                        </div>
                    </td>
                    <td data-label="Email">{{ data.email }}</td>
                    <td data-label="Status">{{ statusString(data.status) }}</td>
                    <td data-label="Ações" v-if="buttonCheck.search || buttonCheck.edit || buttonCheck.delete">
                        <button-show v-if="buttonCheck.show" :route="'user-show'" :id-params="data.id"/>
                        <button-edit v-if="buttonCheck.edit" :route="'user-edit'" :id-params="data.id"/>
                        <button-delete v-if="buttonCheck.delete" @click="openModal(data)"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <button-pagination :pagination="user.itens" :offset="3" @paginate="loadUsers"/>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ModalComponent from '@/components/modal/ModalComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonShow from '@/components/buttons/ButtonShow'
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import {ACTION_MESSAGES} from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Usuário')

        let searchParamsStorage = this.$store.state.user.searchParams
        if(Object.keys(searchParamsStorage).length > 0) {
          this.formDate = searchParamsStorage
        }

        setTimeout(() => {
            this.loadUsers(1)
        }, 500);
        if (window.innerWidth <= 991) {
            this.filter = false
            this.btnFilter = true
        }

    },

    name: 'UserSearch',

    components: {
        PainelComponent,
        ModalComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonOne,
        ButtonAdd,
        ButtonEdit,
        ButtonShow,
        ButtonDelete,
        ButtonPagination,
    },

    data() {
        return {
            switches: false,
            buttonAdd: {
                route: '/user/create',
                icon: 'plus',
                tooltip: 'Adicionar'
            },

            buttonOne: {
                icon: '',
                tooltip: 'Buscar',
                name: 'Buscar',
                type: 'button'
            },

            buttonTwo: {
                icon: 'search',
                tooltip: '',
                name: '',
                type: 'button'
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false,
                show: false,
            },

            object: '',

            formDate: {
                name: '',
                status: ''
            },

            filter: true,
            btnFilter: false,

            idModal: '',
            message: '',
            visible: false,
            searchSubmit: false,
            searchParams: ''
        }
    },

    methods: {
        filterWindow() {
            this.filter = !this.filter
        },

        submit() {
            this.loadUsers(1)
            this.searchSubmit = true;
        },

        loadUsers(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadUsers', {...this.params, page})
                    .then((r) => {
                        this.checkACL()
                        this.object = r.itens.data
                        if(this.searchParams ==1)
                        this.$store.commit('UPDATE_SEARCH_PARAMS_USERS', this.params)

                        this.formDate = this.params;
                        this.searchParams = 1
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.itens.data == '') {
                                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false
                });
            }
        },

        pluralSingOne(value) {
            if (value > 1)
                return "Usuário cadastrados"
            return "Usuários cadastrado"
        },

        pluralSingTwo(value) {
            if (value > 1)
                return "Usuário contratados"
            return "Usuários contratado"
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 2)
                .then((r) => {

                    if (r.data.indexOf('user-search') !== -1) {

                        if (r.data.indexOf('user-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('user-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('user-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('user-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }

                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        openModal(data) {
            if (data.id == this.me.id) {
                this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorMe);
                return false
            }
            this.visible = !this.visible
            this.message = `${this.me.name} , deseja realmente deletar ${data.name}`
            this.idModal = data.id
        },

        closeModal() {
            this.visible = !this.visible
        },

        destroyUser() {
            this.visible = !this.visible
            this.$store.dispatch('destroyUser', this.idModal)
                .then(() => {
                    this.loadUsers(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault)
                })
                .catch(() => {
                    this.loadUsers(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorDefault)
                })
        },

        statusString(value) {
            if (value == 1)
                return "Ativo"
            if (value == 2) {
                return "Inativo"
            }
        }
    },

    computed: {
        user() {
            return this.$store.state.user.items
        },

        me() {
            return this.$store.state.auth.me
        },

        params() {
          let searchParamsStorage = this.$store.state.user.searchParams
          if(Object.keys(searchParamsStorage).length > 0)
            return this.$store.state.user.searchParams

            return {
                page: this.user.current_page,
                name: this.formDate.name,
                status: this.formDate.status
            }
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }

}
</script>
<style src="@/assets/css/filterSearch.css"></style>

<style>
.icon-message > img {
    height: 36px;
    margin-right: 10px;
    filter: invert(38%) sepia(6%) saturate(550%) hue-rotate(353deg) brightness(95%) contrast(88%);
}
</style>

<style scoped>
.container-avatar-user-search {
    display: flex;
    align-items: center;
    height: auto;
}

.container-avatar-user-search > img {
    border-radius: 50%;
    margin-right: 15px;
    height: 3rem;
    width: 3rem;
}

.desk-btn {
    display: none;
}

.mobi-btn {
    display: inline;
}

@media (max-width: 991px) {
    .container-avatar-user-search {
        height: 100%;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
    }

    .container-avatar-user-search > img {
        margin-right: 0;
        margin-left: 10px;
        height: 2rem;
        width: 2rem;
    }

    .desk-btn {
        display: inline;
    }

    .mobi-btn {
        display: none;
    }

}
</style>