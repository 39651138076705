<template>
  <div class="container-card">
    <h1>{{ title }}</h1>
    <div class="painelContent">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PainelComponent',
  props: {
    title: {
      type: String
    }
  }
};
</script>

<style>
.container-card {
  position: relative;
  padding: 2%;
  margin: 3% 2% 5% 2%;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
}

.container-card .painelContent {
  clear: both;
}

.container-card h1 {
  margin-bottom: 2%;
  font-size: 1.5rem;
  color: #1d1d1b;
  position: relative;
  left: 10px;
  text-transform: uppercase;
}

.container-card h1::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 3px;
  left: -10px;
  background-color: #f97f2c;
}

@media (max-width: 991px) {
  .container-card h1 {
    font-size: 1.1rem;
  }
}
</style>
