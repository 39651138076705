export default {
  namePage: 'Home',

  selectActiveInactive: [
    { value: '1', label: 'Ativo' },
    { value: '2', label: 'Inativo' }
  ],

  selectSpotPoweOn: [
    { value: '1', label: 'Desligar' },
    { value: '2', label: 'Ligar' }
  ],

  selectStatusPlayerOnOff: [
    { value: '1', label: 'Offline' },
    { value: '2', label: 'Online' }
  ],

  selectTimeZone: [
    { value: '1', label: 'ACT Hora do Acre UTC−5' },
    { value: '2', label: 'AMT Hora do Amazonas UTC−4' },
    { value: '3', label: 'BRT Hora de Brasília UTC−3' },
    { value: '4', label: 'FNT Hora de Fernando de Noronha UTC−2' }
  ],

  selectNoYes: [
    { value: '1', label: 'Não' },
    { value: '2', label: 'Sim' }
  ],

  selectAlingMenuTable: [
    { value: '1', label: 'Texto alinhado a esquerda' },
    { value: '2', label: 'Texto centralizado' },
    { value: '3', label: 'Texto alinhado a direita' },
    { value: '4', label: 'Texto justificado' }
  ],

  days_week: [
    { label: 'Domingo', value: 1 },
    { label: 'Segunda-feira', value: 2 },
    { label: 'Terça-feira', value: 3 },
    { label: 'Quarta-feira', value: 4 },
    { label: 'Quinta-feira', value: 5 },
    { label: 'Sexta-feira', value: 6 },
    { label: 'Sábado', value: 7 }
  ],

  selectContentSpot: [],
  selectObituaryList: [],
  selectVersionAndroid: [],
  selectVersionApp: [],
  selectNoticeApp: [],
  selectContentFolder: [],
  selectFontsMenuTable: [],
  selectNoticeListUrl: [],
  selectCityClimate: [],
  selectPermissions: [],
  selectGroupUser: [],
  selectCompany: [],
  selectCompanyRestrict: [],
  selectCityState: [],
  selectCity: [],
  selectResolutionPlayer: [],
  selectTypeMediaPlayer: [],
  selectSegmentPlayer: [],
  selectSpot: [],
  selectLayoutCampaign: [],
  selectNotSearch: 'Nada foi encontrado com os parâmetros informados!'
};
