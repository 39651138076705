<template>
  <painel-component :title="'Informações da Pasta'">
    <div class="btn-close" @click="$emit('close-content-file')"></div>

    <form class="mt-3">
      <row-component>
        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
          <label>Nome do Arquivo:</label>
          <p class="field-text">{{ formDate.name_original }}</p>
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
          <label>Tipo do Arquivo:</label>
          <p class="field-text">{{ formDate.type }}</p>
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
          <label>Criador:</label>
          <p class="field-text">{{ formDate.creator_user_name }}</p>
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
          <label>Tamanho:</label>
          <p class="field-text">{{ formDate.size }}</p>
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
          <label>Data de Envio:</label>
          <p class="field-text">{{ formDate.date_send }}</p>
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
          <label>Hora de Envio:</label>
          <p class="field-text">{{ formDate.hour_send }}</p>
        </row-col-component>
      </row-component>
    </form>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
  created() {
    this.loadContentFile();
    this.checkACL();
  },

  props: {
    id: { required: true }
  },

  name: 'ContentFileShow',
  components: {
    PainelComponent,
    RowColComponent,
    RowComponent
  },

  data() {
    return {
      formDate: {
        content_folder_id: '',
        creator_user_id: '',
        creator_user_name: '',
        name_original: '',
        size_original: '',
        type: '',
        time: '',
        created_at: '',
        name_encoded: '',
        size: '',
        link_url: '',
        photo_user: '',
        date_send: '',
        hour_send: ''
      }
    };
  },

  methods: {
    fileRouter(id) {
      this.$router.push({ name: 'content-file-search', params: { id: id } });
    },

    loadContentFile() {
      this.$store.dispatch('updateNamePage', 'Conteúdo');
      this.$store
        .dispatch('loadContentFile', this.id)
        .then(r => {
          this.formDate = r;
        })
        .catch(() => {
          this.$store.commit('addToast', ACTION_MESSAGES.errorDefault);
        });
    },

    async checkACL() {
      this.$store
        .dispatch('ACLitens', 9)
        .then(r => {
          if (r.data.indexOf('content-file-search') !== -1) {
            return;
          } else {
            this.$router.push({ name: 'home' });
          }
        })
        .catch(() => {
          this.$router.push({ name: 'home' });
        });
    }
  }
};
</script>

<style src="@/assets/css/tabs.css"></style>

<style scoped>
.btn-close {
  top: 0;
  right: 0;
  margin: 1%;
  position: absolute;
}

.field-text {
  overflow: hidden;
  white-space: nowrap;
  height: 42px;
  color: black;
  font-weight: bold;
  font-size: 1.2rem;
  background-color: #f0f0f0;
  padding: 0.5rem;
  margin: 0;
  text-overflow: ellipsis;
}

label {
  margin-bottom: 1%;
  color: #1d1d1b;
  position: relative;
  left: 10px;
  text-transform: uppercase;
}

label::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 3px;
  left: -10px;
  background-color: #f97f2c;
}

.button-voltar {
  width: 100%;
  padding: 10px;
  line-height: 20px;
  height: 42px;
  background-color: #66615b;
  border-radius: 5px;
  border: 0;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
}

.button-voltar:not([disabled]):hover,
.button-voltar:not([disabled]):active {
  color: #fff;
  background-color: #505050 !important;
  border-color: #505050;
}

i.icon img {
  vertical-align: bottom;
  filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(254deg) brightness(110%) contrast(110%);
}

@media (max-width: 991px) {
  .btn-close {
    margin: 3%;
  }
}
</style>
